<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_helpdesk">
    <div class="modal-dialog">
      <!-- <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading> -->
      <Form
        class="form"
        @invalid-submit="onInvalidSubmit"
        @submit="onSubmit"
        :initial-values="formValues"
        :validation-schema="schema"
        autocomplete="off"
      >
        <!-- v-slot="{ values }" -->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Contatta il cerimoniale</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_helpdesk_closex"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <div class="ps-5 pe-5 repeater-form">
                <div class="row pb-4">
                  <div class="col-md-4">
                    <label class="fw-bold text-gray-700 pe-3">Cognome </label>
                    <Field name="surname" class="form-control" disabled />
                    <ErrorMessage v-show="view_errors" name="surname" />
                  </div>
                  <div class="col-md-4">
                    <label class="fw-bold text-gray-700 pe-3">Nome </label>
                    <Field name="name" class="form-control" disabled />
                    <ErrorMessage v-show="view_errors" name="name" />
                  </div>
                  <div class="col-md-4">
                    <label class="fw-bold text-gray-700 pe-3">Email </label>
                    <Field name="email" class="form-control" disabled />
                    <ErrorMessage v-show="view_errors" name="max_sessions" />
                  </div>
                  <!-- <div class="col-md-3">
                    <label class="fw-bold text-gray-700 pe-3">Tipologia </label>
                    <Field class="form-select" name="id_language" as="select">
                      <option
                        v-for="language in lookupHelpdesk"
                        :key="language.id"
                        :value="language.id"
                      >
                        {{ language.label }}
                      </option>
                    </Field>
                    <ErrorMessage v-show="view_errors" name="max_sessions" />
                  </div> -->
                </div>
                <div class="row pb-4">
                  <div class="col-md-12">
                    <label class="fw-bold text-gray-700 pe-3">Richiesta </label>
                    <Field name="request" class="form-control" as="textarea" />
                    <ErrorMessage v-show="view_errors" name="request" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_helpdesk_close_button"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-light-success text-success rounded blueFit fs-6"
            >
              Salva ed invia
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import moment from "moment";
import { format } from "@/functions/formatDate";

import { callFunctionAfterAlert, alertFailed } from "@/functions/swAlert";
import { API } from "@/enums/costanti";
import { useApiCall } from "@/composables/apiCall";
import { formatDateITA } from "@/functions/formatDate.js";

export default {
  name: "add-invitation-modal",
  components: {
    Field,
    ErrorMessage,
    Form,
  },
  props: {
    invito: {
      type: Number,
    },
    payload: {
      type: Object,
    },
    id_event: {
      type: Number,
    },
    id_year: {
      type: Number,
    },
    id_person: {
      type: Number,
    },
    id_invitation: {
      type: Number,
    },
    id_type: {
      type: Number,
    },
    guid_invitation: {
      type: String,
    },
    guid_person: {
      type: String,
    },
  },
  emits: ["getList"],
  setup(props, { emit }) {
    const view_errors = ref(false);
    const disableButton = ref(false);
    const onInvalidSubmit = (v) => {
      view_errors.value = true;

      alertFailed("Inserire i campi obbligatori");
    };
    const onSubmit = async (values, { resetForm }) => {
      await callFunctionAfterAlert(
        API.HELPDESK_REQUEST_ADD,
        values,
        `Continuando verrà inviata una email all'amministratore`,
        "Richiesta inviata con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((isAddedorModifiedsession) => {
        if (isAddedorModifiedsession) {
          if (!props.id_invitation) {
            resetForm();
            // date_var.value = null;
          }

          document.getElementById("kt_modal_helpdesk_close_button").click();
        }
      });
    };

    const schema = yup.object().shape({
      name: yup.string().required("Obbligatorio"),
      surname: yup.string().required("Obbligatorio"),
      email: yup
        .string()
        .email("Inserire una email valida")
        .required("Obbligatorio"),
      request: yup.string().required("Obbligatorio"),
    });

    const formValues = ref({
      name: props.invito.name,
      surname: props.invito.surname,
      email: props.invito.email,
      id_event: props.id_event,
      id_year: props.id_event,

      id_person: props.id_person,
      id_invitation: props.id_invitation,
      // id_type: props.id_type,
      id_user: props.guid_person,
    });

    const { element: lookups, getElement: get_lookups } = useApiCall(
      API.LOOKUP_GET,
      { keys: "ht" }
    );

    get_lookups();

    return {
      lookups,
      formValues,
      schema,
      onSubmit,
      onInvalidSubmit,
      disableButton,
      view_errors,
    };
  },
  computed: {
    lookupHelpdesk() {
      return this.lookups ? this.lookups.result[0].helpdesk_type : [];
    },
  },
};
</script>

<style scoped>
.modal-content {
  width: 150%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
    .border-active
  ):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
ErrorMessage {
  color: red;
}
</style>
