<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div>
    <div class="row mt-5">
      <div class="col-4">
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_invitation"
            @click="selected_id_invitation = null"
            :disabled="!id_year || !id_event"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>crea invito
          </button>

          <ModalAddInvitation
            :key="rerender"
            :id_invitation="selected_id_invitation"
            :payload="payload"
            :id_event="id_event"
            :id_year="id_year"
            @getList="$emit('getList')"
            @rerender="rerender++"
          />
        </div>
      </div>
      <div class="col-4">
        <!-- payload.id_status == 4 || payload.id_status == 8 -->
        <!-- payload.id_status == 5 -->
        <button
          v-if="
            (payload.id_tab == 2 || payload.id_tab == 4) &&
            objectList &&
            objectList.results &&
            objectList.results.length
          "
          type="button"
          class="btn btn-light text-gray-700 fs-6 text-start"
          @click="downloadListaDaConfermare(payload.id_tab)"
          :disabled="!id_year || !id_event"
        >
          <i class="bi bi-plus fs-6 text-gray-700"></i>esporta lista da
          confermare
        </button>
        <!-- payload.id_tab == 5 || -->
        <button
          v-if="
            (payload.id_tab == 3 || payload.id_tab == 5) &&
            objectList &&
            objectList.results &&
            objectList.results.length
          "
          type="button"
          class="btn btn-light text-gray-700 fs-6 text-start"
          @click="downloadListaDaConfermare(payload.id_tab)"
          :disabled="!id_year || !id_event"
        >
          <i class="bi bi-plus fs-6 text-gray-700"></i>esporta lista confermati
        </button>
      </div>
      <div class="col-4">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="objectList && objectList.record > 0">
            {{
              payload.rowstoskip + payload.fetchrows - (payload.fetchrows - 1)
            }}
            -
            {{
              payload.rowstoskip + payload.fetchrows > objectList.record
                ? objectList.record
                : payload.rowstoskip + payload.fetchrows
            }}
          </b>
          <b v-else> 0 </b>
          inviti di
          <b>{{ objectList && objectList.record ? objectList.record : 0 }}</b>
        </div>
      </div>
    </div>
    <br />

    <template v-if="!payload.id_year || !payload.id_event">
      <div class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5">
        <span class="text-gray-700 ps-3 fs-2">
          Inserire anno ed evento per vedere gli inviti
          {{ from_invitations ? "" : "ed inserirne di nuovi" }}
        </span>
      </div>
    </template>
    <Datatable
      v-else
      :key="loaded"
      :table-header="tableHeader"
      :table-data="objectList ? objectList.results : []"
      :status="status"
      :loading="!loaded"
      :enableItemsPerPageDropdown="
        objectList && objectList.record >= 25 ? true : false
      "
      :total="objectList ? objectList.record : 0"
      :rowsPerPage="payload.fetchrows"
      :currentPage="payload.rowstoskip ? currentPage : 1"
      :order="payload.sortorder"
      :sortLabel="payload.sortcolumn"
      @sort="
        $emit('setPayload', {
          name: 'sortcolumn',
          value: $event.columnName,
        });
        $emit('setPayload', {
          name: 'sortorder',
          value: $event.order,
        });
        $emit('getList');
      "
      @items-per-page-change="
        $emit('setPayload', {
          name: 'fetchrows',
          value: $event,
        });
        $emit('getList');
      "
      @current-change="
        currentPage = $event;
        $emit('setPayload', {
          name: 'rowstoskip',
          value: payload.fetchrows * ($event - 1),
        });
        $emit('getList');
      "
      @resetFilters="$emit('resetFilters')"
    >
      <template v-slot:cell-id="{ row: data }"
        ><div class="">
          {{ data.id_invitation }}
        </div>
      </template>
      <template v-slot:cell-type="{ row: data }"
        ><div class="">
          {{ data.type }}
        </div>
      </template>
      <template v-slot:cell-event="{ row: data }"
        ><div class="">
          {{ data.event }}
        </div>
      </template>
      <template v-slot:cell-name="{ row: data }"
        ><div class="d-flex">
          <div>{{ data.name }}</div>
          <div>
            <Popper v-if="data.to_pay" arrow content="Pagante">
              <i
                class="bi bi-cash-coin text-center ms-2"
                :class="data.payed ? 'text-success' : 'text-danger'"
              ></i>
            </Popper>
          </div>
        </div>
      </template>
      <template v-slot:cell-surname="{ row: data }"
        ><div class="d-flex">
          <div>{{ data.surname }}</div>
        </div>
      </template>
      <template v-slot:cell-group_sender="{ row: data }"
        ><div class="d-flex tab-long">
          <div>
            <EtichettaGruppo :label="data.group" :bg_color="data.group_color" />
            <!-- <h1
              class="mb-0 badge"
              :class="
                data.group === 'Consiglieri federali / Presidenti CR'
                  ? 'badge-light-success'
                  : 'badge-light-primary'
              "
            >
              {{ data.group ? data.group : "Cerimoniale" }}
            </h1> -->
          </div>
        </div>
      </template>
      <template v-slot:cell-group_receiver="{ row: data }"
        ><div class="d-flex tab-long">
          <div>
            <EtichettaGruppo
              :label="data.person_group"
              :bg_color="data.color"
            />
            <!-- <h1
              class="mb-0 badge"
              :class="`badge-light-${
                data.person_id_group
                  ? GRUPPI[data.person_id_group]
                  : 'Cerimoniale'
              }`"
            >
              {{ data.person_group ? data.person_group : "Cerimoniale" }}
            </h1> -->
          </div>
        </div>
      </template>
      <template v-slot:cell-date_of_birth="{ row: data }"
        ><div class="tab-long d-flex">
          <div>
            {{
              data.date_of_birth
                ? formatDateITANoTime(data.date_of_birth)
                : "DA INSERIRE"
            }}
          </div>
          <!-- <Popper arrow class="col-3" v-if="!isMaggiorenne(data.date_of_birth)"> -->
          <button type="button" v-if="!isMaggiorenne(data.date_of_birth)">
            <i
              class="bi bi-exclamation-triangle-fill text-danger text-center"
            ></i>
          </button>
          <!-- <template #content>
              <div>Minorenne</div>
            </template> -->
          <!-- </Popper> -->
        </div>
      </template>
      <!-- <template v-slot:cell-parking="{ row: data }"
        ><div class="">
          {{ data.parking }}
        </div>
      </template> -->
      <!-- <template v-slot:cell-hotel="{ row: data }"
        ><div class="">
          {{ data.hotel }}
        </div>
      </template> -->
      <template v-slot:cell-status="{ row: data }"
        ><div class="">
          <h1
            class="mb-0 badge"
            :class="
              data.status === 'Confermato'
                ? 'badge-light-success'
                : data.status === 'nuovo'
                ? 'badge-light-primary'
                : data.status === 'Rifiutato' || data.status === 'Annullato'
                ? 'badge-light-danger'
                : data.status === 'Inviato'
                ? 'badge-light-primary'
                : data.status === 'Da confermare'
                ? 'badge-light-accettato'
                : data.status === 'bozza'
                ? 'badge-light-warning'
                : data.status === 'consiglio federale'
                ? 'badge-light-federali'
                : data.status === 'da confermare C.F. / PRES. CCRR'
                ? 'badge-light-da-confermare-ccrr'
                : ''
            "
          >
            {{ data.status }}
          </h1>
          <Popper
            v-if="data.cf_invite"
            arrow
            class="ms-2"
            :content="`Invito inviato da ${data.cf_invite_person}`"
          >
            <i class="bi bi-info-circle-fill text-center"></i>
          </Popper>
        </div>
      </template>

      <template v-slot:cell-options="{ row: data }"
        ><div class="btn-group">
          <router-link :to="`invito/${data.id_invitation}`">
            <button
              class="btn btn-sm dropdown p-1"
              type="button"
              @click="selected_id_invitation = data.id_invitation"
            >
              <i class="bi bi-pen text-dark fs-4"></i></button
          ></router-link>

          <button
            v-if="payload.id_tab == 1"
            class="btn btn-sm dropdown p-1"
            type="button"
            @click="
              sendInvitation(`${data.name} ${data.surname}`, data.id_invitation)
            "
          >
            <i class="bi bi-send text-dark fs-4"></i>
          </button></div
      ></template>
    </Datatable>
  </div>
</template>

<script>
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ModalAddInvitation from "./ModalAddInvitation.vue";

import { ref } from "vue";
import { callFunctionAfterAlert } from "@/functions/swAlert";
import { API, GRUPPI } from "@/enums/costanti";
import { formatDateITANoTime, isMaggiorenne } from "@/functions/formatDate.js";
import Popper from "vue3-popper";
import EtichettaGruppo from "@/components/groups/EtichettaGruppo.vue";

export default {
  name: "TableTornei",
  components: {
    Popper,
    Datatable,
    ModalAddInvitation,
    EtichettaGruppo,
  },
  emits: ["getList", "resetFilters", "setPayload"],
  props: {
    objectList: {
      type: Object,
    },
    loaded: {
      type: Boolean,
    },
    status: {
      type: Number,
    },
    payload: {
      type: Object,
    },
    pressedSearch: {
      type: Boolean,
    },
    id_year: {
      type: Boolean,
    },
    id_event: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const tableHeader = ref([
      {
        name: "Cognome",
        key: "surname",
      },
      {
        name: "Nome",
        key: "name",
      },

      {
        name: "Data",
        key: "date_of_birth",
      },
      {
        name: "Tipologia",
        key: "type",
      },
      {
        name: "Gruppo Invitante",
        key: "group_sender",
      },
      {
        name: "Gruppo Invitato",
        key: "group_receiver",
      },
      {
        name: "Status",
        key: "status",
      },

      {
        key: "options",
        sortable: false,
      },
    ]);

    const send_invite = async (id_session, session_name) => {
      await callFunctionAfterAlert(
        API.SESSIONS_DEL,
        { id_session },
        `Sei sicuro di voler cancellare <b>${session_name}</b>?`,
        "Sessionre cancellata con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((deletedElement) => {
        if (deletedElement) {
          emit("getList");
        }
      });
    };

    const selected_id_invitation = ref(0);

    const currentPage = ref(1);

    const rerender = ref(0);

    const downloadListaDaConfermare = (id_tab) => {
      if (props.payload.id_year && props.payload.id_event) {
        // props.payload.id_tab ? 4 : ""
        const url = `${
          process.env.VUE_APP_IS_BRANCH_PROD == 1
            ? process.env.VUE_APP_ENDPOINT_API_PROD
            : process.env.VUE_APP_ENDPOINT_API_TEST
        }${API.INVITATION_EXPORT_V2}?id_status=${id_tab}&id_year=${
          props.payload.id_year
        }&id_event=${props.payload.id_event}&request=${localStorage.getItem(
          "tokenAdb2c"
        )}`;
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = url;
        link.setAttribute("download", `Da_confermare.csv`);
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          document.body.removeChild(link);
        }, 500);
      }
    };

    const sendInvitation = async (name, id_invitation) => {
      await callFunctionAfterAlert(
        API.INVITATIONS_MAIL_RESEND,
        {
          id_invitation,
          id_status: 2,
        },
        `Continuando verrà rimandata la mail di invito a <b>${name}</b>?`,
        "Email inviata con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((deletedElement) => {
        if (deletedElement) {
          emit("getList");
        }
      });
    };

    return {
      sendInvitation,
      downloadListaDaConfermare,
      rerender,
      currentPage,
      tableHeader,
      send_invite,
      selected_id_invitation,
      formatDateITANoTime,
      isMaggiorenne,
      GRUPPI,
    };
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
.cerchio {
  position: relative;
  width: 15px; /* Dimensione del cerchio */
  height: 15px; /* Dimensione del cerchio */
  border-radius: 50%; /* Rende il cerchio */
  background-color: #3498db; /* Colore di riempimento */
  color: #fff; /* Colore del testo */
  font-size: 11px; /* Dimensione del testo */
  display: flex; /* Per allineare il testo verticalmente e orizzontalmente */
  justify-content: center;
  align-items: center;
}
</style>
