<template>
  <div class="card pb-3 mt-0 shadow mb-3">
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <CropperModal
      :id="id_modal_cropper"
      :imageUrl="
        !invito.photo.includes('default') ? `${blob}${invito.photo}` : ''
      "
      @fileRitagliato="fileRitagliato"
      :isOpenModal="isOpenModal"
      @closeModal="isOpenModal = false"
    />
    <div class="card-body">
      <div class="">
        <div class="row">
          <div class="align-items-center mb-2 d-flex justify-content-between">
            <h1 class="align-items-center fw-bolder my-2 fs-3">DATI PERSONA</h1>

            <div>
              <!-- <EtichettaGruppo
                class="me-2"
                :label="invito.group"
                :bg_color="invito.group_color"
              /> -->

              <h1
                v-if="(invito.id_status != 2 && isGuest) || !isGuest"
                class="mb-0 badge text-uppercase"
                :class="
                  invito.status === 'Confermato'
                    ? 'badge-light-success'
                    : invito.status === 'nuovo'
                    ? 'badge-light-primary'
                    : invito.status === 'Rifiutato' ||
                      invito.status === 'Annullato'
                    ? 'badge-light-danger'
                    : invito.status === 'Inviato'
                    ? 'badge-light-primary'
                    : invito.status === 'Da confermare'
                    ? 'badge-light-accettato'
                    : invito.status === 'bozza'
                    ? 'badge-light-warning'
                    : invito.status === 'consiglio federale'
                    ? 'badge-light-federali'
                    : ''
                "
              >
                {{ invito.status }}
              </h1>
            </div>
          </div>

          <div class="row mb-1">
            <div class="ps-0 col-3 text-center">
              <!-- @click="clickImportFile" -->

              <div
                class="my-1 cursor-pointer"
                @click="isOpenModal = true"
                data-bs-toggle="modal"
                :data-bs-target="`#cropperModal1`"
              >
                <input
                  type="file"
                  class="d-none"
                  name="avatar"
                  accept=".jpg, .jpeg, .png"
                  id="addAvatarOspite"
                  @change="onInputChange"
                />
                <!-- <BlankProfile /> -->
                <img
                  v-if="!file_controllato"
                  :src="`${blob}${invito.photo}&t=${timestamp}`"
                  class="rounded-circle width-100 bg-black"
                  alt="..."
                  :style="
                    invito.photo.includes('default')
                      ? ''
                      : 'border: 2px solid #cbcbcb'
                  "
                  style="width: 5rem; height: 5rem"
                />
                <ErrorMessage name="photo" />
                <!-- <ProfilePictureX /> -->
              </div>
            </div>
            <div class="pt-2 pe-0 col-9 my-auto">
              <Field
                class="form-control"
                name="surname"
                disabled
                @input="
                  $emit('editValue', {
                    el: 'surname',
                    value: $event.target.value,
                  })
                "
              />
              <ErrorMessage name="surname" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="pt-2 pe-0 col-12">
              <Field
                class="form-control"
                name="name"
                disabled
                @input="
                  $emit('editValue', {
                    el: 'name',
                    value: $event.target.value,
                  })
                "
              />
              <ErrorMessage name="name" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="pt-2 pe-0 col-6">
              <VueDatePicker
                placeholder="Data di nascita"
                :max-date="new Date()"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                autoApply
                v-model="date_of_birth_var"
                @update:modelValue="
                  date_of_birth_var = $event
                    ? moment($event).format('YYYY-MM-DD')
                    : null;
                  $emit('editValue', {
                    el: 'date_of_birth',
                    value: $event ? moment($event).format('YYYY-MM-DD') : null,
                  });
                "
                :disabled="
                  (invito.id_status != 2 && isGuest) || invito.id_status == 5
                "
              />
              <ErrorMessage name="date_of_birth" />
            </div>
            <div class="pt-2 pe-0 col-6">
              <Field
                class="form-control"
                name="phone_number"
                placeholder="Cellulare"
                :disabled="
                  (invito.id_status != 2 && isGuest) || invito.id_status == 5
                "
                @input="
                  $emit('editValue', {
                    el: 'phone_number',
                    value: $event.target.value,
                  })
                "
              />
              <ErrorMessage name="phone_number" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="pt-2 pe-0 col-6">
              <Field class="form-control" name="email" disabled />
              <ErrorMessage name="email" />
            </div>
            <div class="pt-2 pe-0 col-6">
              <Field
                class="form-control"
                placeholder="Sessioni massime"
                name="max_sessions"
                :disabled="isGuest"
              />
              <ErrorMessage name="max_sessions" />
            </div>
          </div>

          <div class="row">
            <div class="pt-2 pe-0 col-12">
              <!-- da inserire :disabled="invito.id_status != 2 && isGuest" -->
              <Field
                as="textarea"
                name="notes"
                class="form-control"
                :class="isGuest ? '' : 'bg-light-success'"
                placeholder="Inserire qui eventuali note"
                :disabled="invito.id_status != 2 && isGuest"
                @input="
                  $emit('editValue', {
                    el: 'notes',
                    value: $event.target.value,
                  })
                "
              />
              <ErrorMessage name="notes" />
            </div>
          </div>
          <div class="row" v-if="!isGuest">
            <div class="pt-2 pe-0 col-12">
              <!-- da inserire :disabled="invito.id_status != 2 && isGuest" -->
              <Field
                as="textarea"
                name="internal_notes"
                class="form-control bg-light-warning"
                placeholder="Inserire qui eventuali note interne"
                :disabled="invito.id_status != 2 && isGuest"
                @input="
                  $emit('editValue', {
                    el: 'internal_notes',
                    value: $event.target.value,
                  })
                "
              />
              <ErrorMessage name="notes" />
            </div>
          </div>
          <div class="row" v-if="!isGuest">
            <div class="pt-2 col-5">
              <span class="fw-bold text-gray-800">INVITO A PAGAMENTO:</span>
            </div>
            <div class="pt-2 pe-0 col-7">
              <Field
                name="payment_invitation"
                class="align-self-start me-2"
                type="checkbox"
                :value="true"
              />
            </div>
          </div>
          <!-- :class="
                invito.status === 'Confermato'
                  ? 'badge-light-success'
                  : invito.status === 'nuovo'
                  ? 'badge-light-primary'
                  : invito.status === 'Rifiutato'
                  ? 'badge-light-danger'
                  : invito.status === 'Inviato'
                  ? 'badge-light-primary'
                  : invito.status === 'Da confermare'
                  ? 'badge-light-accettato'
                  : invito.status === 'bozza'
                  ? 'badge-light-warning'
                  : invito.status === 'consiglio federale'
                  ? 'badge-light-federali'
                  : ''
              " -->
          <div
            class="align-items-center mb-2 d-flex justify-content-between mt-4"
            v-if="!isGuest"
          >
            <div class=""></div>
            <a
              :href="invito.link_invito_cf"
              v-if="invito.link_invito_cf"
              target="_blank"
              class="me-2"
            >
              <h1
                class="mb-0 badge text-uppercase mt-4 col-3 badge-light-warning me-2 w-100"
                style="cursor: pointer"
              >
                Visualizza Invito Collaboratore
              </h1>
            </a>
            <a :href="invito.link" target="_blank">
              <h1
                class="mb-0 badge text-uppercase mt-4 col-3 badge-light-primary w-100"
                style="cursor: pointer"
              >
                Visualizza link invito
              </h1>
            </a>
            <button
              type="button"
              class="ms-auto mt-auto badge bg-light-success text-success rounded blueFit fs-6"
              style="width: 12rem"
              @click="$emit('salvaNote')"
            >
              SALVA NOTE
            </button>
          </div>
          <!-- <div class="row">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800"
                  >HOTEL: <Field type="checkbox" name="hotel" :value="true"
                /></span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <Field
                  as="textarea"
                  name="notes"
                  class="form-control"
                  placeholder="Inserire qui eventuali note riguardanti l'hotel"
                />
              </div>
            </div> -->
          <!-- <div class="row">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800"
                  >PARCHEGGIO:
                  <Field Field type="checkbox" name="parking" :value="true"
                /></span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <Field
                  as="textarea"
                  class="form-control"
                  name="parking_notes"
                  placeholder="Inserire qui eventuali note riguardanti il parcheggio"
                />
              </div>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import { formatDateITA } from "@/functions/formatDate";
import { Field, ErrorMessage } from "vee-validate";
import VueDatePicker from "@vuepic/vue-datepicker";
import { format, dateToReset } from "@/functions/formatDate";
import moment from "moment";
import { fileSender } from "@/db/dbManag";
import { API } from "@/enums/costanti";
import { alertFailed } from "@/functions/swAlert";
import useFileList from "@/composables/file-list";
import EtichettaGruppo from "@/components/groups/EtichettaGruppo.vue";
import CropperModal from "./CropperModal.vue";

export default defineComponent({
  name: "qr",
  emits: ["editValue", "salvaNote", "refresh"],
  props: {
    invito: {
      type: Object,
      EtichettaGruppo,
    },
    name_surname_props: {
      type: String,
    },
    isGuest: {
      type: Boolean,
    },
    is_consigliere_federale: {
      type: Boolean,
    },
  },
  components: {
    Field,
    VueDatePicker,
    ErrorMessage,
    CropperModal,
  },
  setup(props, { emit }) {
    const name_surname_props_copy = ref(props.name_surname_props);
    const date_of_birth_var = ref(
      props.invito.date_of_birth == dateToReset
        ? ""
        : moment(props.invito.date_of_birth).format("YYYY-MM-DD")
    );

    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(
        e.target.files,
        ".jpg, .jpeg, .png, .gif, .bmp, .tiff, .webp, .svg"
      );
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    watch(
      () => file.value.file,
      (value) => {
        if (file.value.file) {
          caricaFoto();
        } else {
          setTimeout(() => {
            file_controllato.value = false;
            errori_txt.value = "";
          }, 1000);
        }
      }
    );

    const file_controllato = ref(false);
    const errori_txt = ref("");
    const isLoading = ref(false);

    const caricaFoto = async () => {
      console.log("caricaFoto", file.value);
      if (!file.value.file) {
        alertFailed(
          "Selezionare un gruppo e caricare un file per procedere con il controllo"
        );
        return;
      }
      file_controllato.value = false;
      errori_txt.value = "";
      isLoading.value = true;
      const bodyFormData = ref(new FormData());
      bodyFormData.value.append("file", file.value.file);
      bodyFormData.value.append("item_id", props.invito.id_user);
      bodyFormData.value.append("id_invitation", props.invito.id);
      bodyFormData.value.append("id_event", props.invito.id_event);
      bodyFormData.value.append("id_user", props.invito.id_user);

      await fileSender(API.ANAGRAPHICS_PHOTO_EDIT, bodyFormData.value).then(
        (res) => {
          file_controllato.value = true;
          isLoading.value = false;
          const { status, data } = res;
          if (status != 200) {
            file.value.status = false;
            alertFailed(
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            errori_txt.value =
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi";
          } else {
            file.value.status = true;
            // QUI DA VEDERE COME SARA LA STRUTTURA DEL JSON
            errori_txt.value = data && data.errori ? data.errori : "";

            emit("refresh");
          }
          file_controllato.value = false;
        }
      );
    };
    const clickImportFile = () => {
      document.getElementById("addAvatarOspite").click();
    };

    const id_modal_cropper = ref(1);

    const fileRitagliato = (fileRitagliato) => {
      console.log(fileRitagliato);
      file.value.file = fileRitagliato;
    };

    const isOpenModal = ref(false);
    return {
      isOpenModal,
      fileRitagliato,
      id_modal_cropper,
      clickImportFile,
      name_surname_props_copy,
      formatDateITA,
      date_of_birth_var,
      moment,
      format,
      blob: process.env.VUE_APP_BLOB,
      timestamp: moment().unix(),
      file_controllato,
      onInputChange,
      isLoading,
      caricaFoto,
    };
  },
  computed: {
    name_surname() {
      return `${this.invito.surname}, ${this.invito.name}`;
    },
  },
});
</script>
