<template>
  <div class="row p-0 mx-auto">
    <div
      v-for="sessione in sessioni"
      :key="sessione.id_session"
      class="col-12 col-md-6 mb-4 px-0"
    >
      <SingolaSessione
        :sessione="sessione"
        :accompagnatori="accompagnatori"
        :invito="invito"
        @single_session="
          console.log('evento sessione');
          $emit('single_session', {
            values: $event.values,
            selectedAccompagnatori: $event.selectedAccompagnatori,
            nonAggiungereASessioniSelezionate:
              $event.nonAggiungereASessioniSelezionate,
          });
        "
        @lastLoungeSelected="
          valoreLoungeSelezionataUltimaSessione = $event;
          consigliereStaModificando = true;
        "
        :numberSessioniOspiteSelected="numberSessioniOspiteSelected"
        :isConsigliere="isConsigliere"
        :valoreLoungeSelezionataUltimaSessione="
          valoreLoungeSelezionataUltimaSessione
        "
        :consigliereStaModificando="consigliereStaModificando"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import SingolaSessione from "./SingolaSessione.vue";
import { useWindowWidth } from "@/composables/getWindowWidth";

export default defineComponent({
  name: "qr",
  components: {
    SingolaSessione,
  },
  emits: ["single_session"],
  props: {
    sessioni: {
      type: Array,
    },
    accompagnatori: {
      type: Array,
    },
    invito: {
      type: Object,
    },
    numberSessioniOspiteSelected: {
      type: Number,
    },
    isConsigliere: {
      type: Boolean,
    },
  },
  setup() {
    const valoreLoungeSelezionataUltimaSessione = ref(0);
    const consigliereStaModificando = ref(false);
    return { valoreLoungeSelezionataUltimaSessione, consigliereStaModificando };
  },
  computed: {
    parametroOldLoungeUltimeSessioneSelezionata() {
      let oldLoungeSessione = false;
      this.sessioni.forEach((sessione) => {
        if (sessione.id_session == this.valoreLoungeSelezionataUltimaSessione) {
          oldLoungeSessione = sessione.old_lounge;
        }
      });
      return false;
    },
  },
});
</script>

<style scoped>
.mw-400 {
  max-width: 400px;
}
</style>
