<template>
  <div class="card pb-3 mt-0 shadow mb-3">
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <CropperModal
      :id="id_modal_cropper"
      :imageUrl="
        !invito.photo.includes('default') ? `${blob}${invito.photo}` : ''
      "
      :isOpenModal="isOpenModal"
      @fileRitagliato="fileRitagliato"
      @closeModal="isOpenModal = false"
    />
    <div class="card-body p-5">
      <div class="">
        <div class="row">
          <div class="align-items-center mb-2 d-flex justify-content-between">
            <h1 class="align-items-center fw-bolder my-2 fs-3">
              OSPITE INVITATO
            </h1>

            <span
              :class="
                invito.payment_invitation
                  ? 'bg-light-warning text-warning'
                  : 'bg-light-success text-success'
              "
              class="me-3 badge rounded fs-6 mb-2"
              style="align-self: center; white-space: normal !important"
            >
              Invito
              {{ invito.payment_invitation ? "a pagamento" : "gratuito" }}</span
            >
            <!-- <h1
              class="mb-0 badge text-uppercase"
              :class="
                invito.status === 'Confermato'
                  ? 'badge-light-success'
                  : invito.status === 'nuovo'
                  ? 'badge-light-primary'
                  : invito.status === 'Rifiutato' ||
                    invito.status === 'Annullato'
                  ? 'badge-light-danger'
                  : invito.status === 'Inviato'
                  ? 'badge-light-primary'
                  : invito.status === 'Da confermare'
                  ? 'badge-light-accettato'
                  : invito.status === 'bozza'
                  ? 'badge-light-warning'
                  : invito.status === 'consiglio federale'
                  ? 'badge-light-federali'
                  : ''
              "
            >
              {{ invito.status }}
            </h1> -->
            <!-- da vedere come mostrare invito pagantre o meno -->
          </div>

          <div class="row mb-1">
            <div class="ps-0 col-3 text-center">
              <div
                class="my-1 cursor-pointer"
                @click="isOpenModal = true"
                data-bs-toggle="modal"
                data-bs-target="#cropperModal1"
              >
                <input
                  type="file"
                  class="d-none"
                  name="avatar"
                  accept=".jpg, .jpeg, .png"
                  id="addAvatarOspite"
                  @change="onInputChange"
                />
                <!-- <BlankProfile /> -->
                <div class="d-flex flex-column align-items-center">
                  <img
                    v-if="!file_controllato"
                    :src="`${blob}${invito.photo}&t=${timestamp}`"
                    class="rounded-circle width-100 bg-black"
                    alt="..."
                    :style="
                      invito.photo.includes('default')
                        ? ''
                        : 'border: 2px solid #cbcbcb'
                    "
                    style="width: 5rem; height: 5rem"
                  />
                  <ErrorMessage name="photo" />
                </div>
                <!-- <ProfilePictureX /> -->
              </div>
            </div>

            <div class="pt-2 pe-0 col-9 my-auto">
              <Field
                class="form-control"
                name="surname"
                disabled
                @input="
                  $emit('editValue', {
                    el: 'surname',
                    value: $event.target.value,
                  })
                "
              />
              <ErrorMessage v-if="showErrors" name="surname" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="pt-2 pe-0 col-12">
              <Field
                class="form-control"
                name="name"
                disabled
                @input="
                  $emit('editValue', {
                    el: 'name',
                    value: $event.target.value,
                  })
                "
              />
              <ErrorMessage v-if="showErrors" name="name" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="pt-2 pe-0 col-6">
              <VueDatePicker
                placeholder="Data di nascita"
                :max-date="new Date()"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                autoApply
                v-model="date_of_birth_var"
                @update:modelValue="
                  date_of_birth_var = $event
                    ? moment($event).format('YYYY-MM-DD')
                    : null;
                  $emit('editValue', {
                    el: 'date_of_birth',
                    value: $event ? moment($event).format('YYYY-MM-DD') : null,
                  });
                "
                :disabled="
                  (invito.id_status != 2 && isGuest) || invito.id_status == 5
                "
              />
              <ErrorMessage v-if="showErrors" name="date_of_birth" />
            </div>
            <div class="pt-2 pe-0 col-6">
              <Field
                class="form-control"
                name="phone_number"
                placeholder="Cellulare"
                :disabled="
                  (invito.id_status != 2 && isGuest) || invito.id_status == 5
                "
                @input="
                  $emit('editValue', {
                    el: 'phone_number',
                    value: $event.target.value,
                  })
                "
              />
              <ErrorMessage v-if="showErrors" name="phone_number" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="pt-2 pe-0 col-12">
              <Field
                class="form-control"
                name="email"
                placeholder="Email"
                disabled
              />
              <ErrorMessage v-if="showErrors" name="email" />
            </div>
          </div>

          <div class="row mb-1">
            <div class="pt-2 pe-0 col-12">
              <Field
                as="textarea"
                name="notes"
                class="form-control bg-light-success"
                placeholder="Inserire qui eventuali note"
                :disabled="invito.id_status != 2 && isGuest"
                @input="
                  $emit('editValue', {
                    el: 'notes',
                    value: $event.target.value,
                  })
                "
              />
              <ErrorMessage v-if="showErrors" name="notes" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import { formatDateITA } from "@/functions/formatDate";
import { Field, ErrorMessage } from "vee-validate";
import VueDatePicker from "@vuepic/vue-datepicker";
import { format, dateToReset } from "@/functions/formatDate";
import moment from "moment";
import BlankProfile from "@/components/invitations/SVG/BlankProfile.vue";
import useFileList from "@/composables/file-list";
import { alertFailed } from "@/functions/swAlert";
import { fileSender } from "@/db/dbManag";
import { API } from "@/enums/costanti";
import CropperModal from "./CropperModal.vue";
export default defineComponent({
  name: "qr",
  emits: ["editValue", "salvaNote", "refresh"],
  props: {
    invito: {
      type: Object,
    },
    name_surname_props: {
      type: String,
    },
    isGuest: {
      type: Boolean,
    },
    is_consigliere_federale: {
      type: Boolean,
    },
    showErrors: {
      type: Boolean,
    },
  },
  components: {
    Field,
    VueDatePicker,
    ErrorMessage,
    CropperModal,
    // BlankProfile,
    // Form,
  },
  setup(props, { emit }) {
    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(
        e.target.files,
        ".jpg, .jpeg, .png, .gif, .bmp, .tiff, .webp, .svg"
      );
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    watch(
      () => file.value.file,
      (value) => {
        if (file.value.file) {
          caricaFoto();
        } else {
          setTimeout(() => {
            file_controllato.value = false;
            errori_txt.value = "";
          }, 1000);
        }
      }
    );

    const file_controllato = ref(false);
    const errori_txt = ref("");
    const isLoading = ref(false);

    const caricaFoto = async () => {
      if (!file.value.file) {
        alertFailed(
          "Selezionare un gruppo e caricare un file per procedere con il controllo"
        );
        return;
      }
      file_controllato.value = false;
      errori_txt.value = "";
      isLoading.value = true;
      const bodyFormData = ref(new FormData());
      bodyFormData.value.append("file", file.value.file);
      bodyFormData.value.append("item_id", props.invito.id_user);
      bodyFormData.value.append("id_invitation", props.invito.id);
      bodyFormData.value.append("id_event", props.invito.id_event);
      bodyFormData.value.append("id_user", props.invito.id_user);

      await fileSender(API.ANAGRAPHICS_PHOTO_EDIT, bodyFormData.value).then(
        (res) => {
          file_controllato.value = true;
          isLoading.value = false;
          const { status, data } = res;
          if (status != 200) {
            file.value.status = false;
            alertFailed(
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            errori_txt.value =
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi";
          } else {
            file.value.status = true;
            // QUI DA VEDERE COME SARA LA STRUTTURA DEL JSON
            errori_txt.value = data && data.errori ? data.errori : "";

            emit("refresh");
          }
          file_controllato.value = false;
        }
      );
    };
    const clickImportFile = () => {
      document.getElementById("addAvatarOspite").click();
    };

    const name_surname_props_copy = ref(props.name_surname_props);
    const date_of_birth_var = ref(
      props.invito.date_of_birth == dateToReset
        ? ""
        : moment(props.invito.date_of_birth).format("YYYY-MM-DD")
    );

    const id_modal_cropper = ref(1);

    const fileRitagliato = (fileRitagliato) => {
      console.log(fileRitagliato);
      file.value.file = fileRitagliato;
    };
    const isOpenModal = ref(false);
    return {
      isOpenModal,
      fileRitagliato,
      id_modal_cropper,
      isLoading,
      onInputChange,
      clickImportFile,
      name_surname_props_copy,
      formatDateITA,
      date_of_birth_var,
      moment,
      format,
      blob: process.env.VUE_APP_BLOB,
      timestamp: moment().unix(),
      file_controllato,
    };
  },
  computed: {
    name_surname() {
      return `${this.invito.surname}, ${this.invito.name}`;
    },
  },
});
</script>
