<template>
  <div class="card shadow mb-7 w-100 col-6 px-2 h-100">
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>

    <div class="accordion-item border-0">
      <h6 class="accordion-header">
        <button
          class="accordion-button m-0 p-6 ps-3 collapsed"
          type="button"
          :data-bs-toggle="hoverCheckbox ? '' : 'collapse'"
          data-bs-target="#panelsStayOpen-collapseThree"
          aria-expanded="true"
          aria-controls="panelsStayOpen-collapseThree"
        >
          <div class="me-auto fw-bolder ms-4 fs-3 text-uppercase">
            Accompagnatori
          </div>

          <span
            v-if="guid_invitation && guid_person"
            :class="
              invito.payment_guest
                ? 'bg-light-warning text-warning'
                : 'bg-light-success text-success'
            "
            class="me-3 badge rounded fs-6 mb-2"
            style="align-self: center; white-space: normal !important"
          >
            Inviti
            {{ invito.payment_guest ? "a pagamento" : "gratuiti" }}</span
          >
          <div
            class="d-flex p-0"
            @mouseover="hoverCheckbox = true"
            @mouseleave="hoverCheckbox = false"
            v-else
          >
            <input
              name="payment_guest"
              class="align-self-start me-2"
              type="checkbox"
              :value="true"
              :checked="payment_guest"
              :disabled="disabled_checkbox"
              @change="$emit('setpayment_guest', $event.target.checked)"
            />
            <label class="">Accompagnatori a pagamento </label>

            <ErrorMessage v-show="view_errors" name="can_invitation_send" />
          </div>
        </button>
      </h6>
      <div
        id="panelsStayOpen-collapseThree"
        class="accordion-collapse collapse show"
        style=""
      >
        <div class="ms-7" v-if="max_guest_sessions_invitation_send">
          Numero di accompagnatori disponibili:
          <b>{{ max_guest_sessions_invitation_send }}</b>
        </div>
        <div class="accordion-body">
          <!-- <div class="row">
            <div class="col-12 px-5 py-5"> -->
          <div
            v-for="accompagnatore in accompagnatoriFromProps"
            :key="accompagnatore.id"
          >
            <AccompagnatoreInserito
              :accompagnatore="accompagnatore"
              :invito="invito"
              :guid_person="guid_person"
              :guid_invitation="guid_invitation"
              :from_add_person_to_session="from_add_person_to_session"
              :consigliere_puo_modificare="
                !max_acc_reached &&
                guid_person &&
                guid_invitation &&
                (invito.id_status == INVITI_STATUS.INVIATO ||
                  (isConsigliere &&
                    invito.id_status == INVITI_STATUS.CONFERMATO))
              "
              @AddFoto="addFotoAcc($event)"
              @Modifica="modificaAcc($event)"
              @Elimina="eliminaAcc($event)"
              @Refresh="$emit('GetAcc')"
            />
          </div>

          <Form
            v-if="
              (!max_acc_reached &&
                guid_person &&
                guid_invitation &&
                (invito.id_status == INVITI_STATUS.INVIATO ||
                  (isConsigliere &&
                    invito.id_status == INVITI_STATUS.CONFERMATO))) ||
              (!max_acc_reached && !guid_person && !guid_invitation)
            "
            class="form ps-2 pe-2"
            style="margin-right: unset; margin-left: unset"
            @invalid-submit="onInvalidSubmit"
            @submit="onSubmit"
            :initial-values="id_group ? selected_group : formValues"
            :validation-schema="schema"
            v-slot="{ values }"
            autocomplete="off"
          >
            <div class="row mt-4 justify-content-center mb-2">
              <div class="ps-0 col-2 text-center">
                <CropperModal
                  :id="id_modal_cropper"
                  :imageUrl="
                    file && file.file && file.file.url ? file.file.url : ''
                  "
                  :isOpenModal="isOpenModal"
                  @fileRitagliato="fileRitagliato"
                  @closeModal="isOpenModal = false"
                />
                <div class="my-7 cursor-pointer">
                  <!-- data-bs-toggle="modal"
                  :data-bs-target="`#cropperModal${id_modal_cropper}`"
                  @click="isOpenModal = true" -->
                  <!--  @click="clickLoadFileAccAdd" -->
                  <input
                    type="file"
                    class="d-none"
                    name="avatar"
                    accept=".jpg, .jpeg, .png"
                    id="addAvatarAccAdd"
                    @change="onInputChange"
                  />
                  <!-- {{ JSON.stringify(file) }} -->

                  <img
                    v-if="file && file.file && file.file.url"
                    :src="file.file.url"
                    class="rounded-circle width-100 bg-black"
                    alt="..."
                    :style="
                      invito
                        ? invito.photo.includes('default')
                          ? ''
                          : 'border: 2px solid #cbcbcb'
                        : 'border: 2px solid #cbcbcb'
                    "
                    style="width: 4rem; height: 4rem"
                  />

                  <!-- <BlankProfile v-else /> -->
                </div>
              </div>
              <div
                class="col-10 row align-items-center"
                v-if="windowWidth <= 767"
              >
                <div class="row">
                  <Field
                    name="surname"
                    class="form-control col-xl-3 mb-1"
                    placeholder="Cognome"
                    :style="errors && errors.surname ? 'border-color: red' : ''"
                  />

                  <Field
                    name="name"
                    class="form-control col-xl-3 mb-1"
                    placeholder="Nome"
                    :style="errors && errors.name ? 'border-color: red' : ''"
                  />
                </div>
                <div class="row">
                  <div class="p-0">
                    <VueDatePicker
                      placeholder="Data di nascita"
                      format="dd/MM/yyyy"
                      :max-date="new Date()"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      :inputClassName="
                        errors && errors.date_of_birth
                          ? 'border-red h-28 w-100 mb-1'
                          : ' h-28 w-100 mb-1'
                      "
                      autoApply
                      :model-value="values.date_of_birth"
                      @update:modelValue="
                        values.date_of_birth = $event
                          ? moment($event).format('YYYY-MM-DD')
                          : null
                      "
                      :style="
                        errors && errors.date_of_birth
                          ? 'border-color: red!important'
                          : ''
                      "
                    />
                    <Field
                      name="date_of_birth"
                      type="hidden"
                      v-model="date_of_birth"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <template v-else>
                <div class="col-7 row align-items-center">
                  <div class="row">
                    <div class="col-12">
                      <Field
                        name="surname"
                        class="form-control col-xl-3 mb-1"
                        placeholder="Cognome"
                        :style="
                          errors && errors.surname ? 'border-color: red' : ''
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <Field
                        name="name"
                        class="form-control col-xl-3 mb-1"
                        placeholder="Nome"
                        :style="
                          errors && errors.name ? 'border-color: red' : ''
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <VueDatePicker
                        placeholder="Data di nascita"
                        format="dd/MM/yyyy"
                        :max-date="new Date()"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        :inputClassName="
                          errors && errors.date_of_birth
                            ? 'border-red h-28 w-100 mb-1'
                            : ' h-28 w-100 mb-1'
                        "
                        autoApply
                        :model-value="values.date_of_birth"
                        @update:modelValue="
                          values.date_of_birth = $event
                            ? moment($event).format('YYYY-MM-DD')
                            : null
                        "
                        :style="
                          errors && errors.date_of_birth
                            ? 'border-color: red !important'
                            : ''
                        "
                      />
                      <Field
                        name="date_of_birth"
                        type="hidden"
                        v-model="date_of_birth"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-3 row align-items-center">
                  <button
                    type="submit"
                    class="mt-4 badge bg-light-success text-success rounded fs-6 px-3 w-100 px-4 mb-2"
                  >
                    Inserisci
                  </button>
                </div>
              </template>
            </div>
            <button
              v-if="windowWidth <= 767"
              type="submit"
              class="mt-4 badge bg-light-success text-success rounded fs-6 px-3 w-100 px-4 mb-2"
            >
              Inserisci
            </button>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@vuepic/vue-datepicker/dist/main.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import BlankProfile from "./SVG/BlankProfile.vue";
import useFileList from "@/composables/file-list";
import moment from "moment";
import { ref, watch } from "vue";
import { requestAppPost } from "@/db/dbManag";
import { API } from "@/enums/costanti";
import AccompagnatoreInserito from "./AccompagnatoreInserito.vue";
import { callFunctionAfterAlert, alertFailed } from "@/functions/swAlert";
import { INVITI_STATUS } from "@/enums/costanti";
import { useWindowWidth } from "@/composables/getWindowWidth";
import { fileSender } from "@/db/dbManag";
import CropperModal from "@/components/user-sessions/CropperModal.vue";
import { generateGUID } from "@/functions/generateGuid";
export default {
  name: "accordion-accompagnatori",
  emits: [
    "GetAcc",
    "setpayment_guest",
    "accompagnatoreInserito",
    "AccEliminato",
  ],
  props: {
    accompagnatori: {
      type: Object,
    },
    guid_person: {
      type: String,
    },
    guid_invitation: {
      type: String,
    },
    invito: {
      type: Object,
    },
    payment_guest: {
      type: Boolean,
    },
    accompagnatori_da_confermare: {
      type: Array,
    },
    from_add_person_to_session: {
      type: Boolean,
      default: false,
    },
    max_acc_reached: {
      type: Boolean,
      default: false,
    },
    max_guest_sessions_invitation_send: {
      type: Number,
    },
    isConsigliere: {
      type: String,
    },
    disabled_checkbox: {
      type: Boolean,
    },
  },
  components: {
    VueDatePicker,
    Form,
    Field,
    ErrorMessage,
    // BlankProfile,
    AccompagnatoreInserito,
    CropperModal,
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const onSubmit = async (values, { resetForm }) => {
      // ENTRA SOLO QUANDO MODAL ADD PERSON FROM SESSION
      if (props.from_add_person_to_session) {
        if (!accompagnatoriFromProps.value) {
          accompagnatoriFromProps.value = [];
        }

        const accompagnatoriFromPropsLength =
          accompagnatoriFromProps.value.length;
        accompagnatoriFromProps.value.push({
          ...values,
          nominative: `${values.name} ${values.surname}`,
          id_guest: accompagnatoriFromPropsLength,
          photo: file.value ? file.value.url : "",
          fileToSend: file.value,
          id: accompagnatoriFromPropsLength,
          id_user: generateGUID(),
        });
        emit("accompagnatoreInserito", accompagnatoriFromProps.value);
        file.value = null;
        resetForm();
        return;
      }
      isLoading.value = true;
      await requestAppPost(API.INVITATIONS_GUEST_ADD, {
        ...values,
        id_user: props.guid_person,
        id_person: props.invito.id_person,
        id_invitation: props.invito.id,
        id_year: props.invito.id_year,
        id_event: props.invito.id_event,
        self_payment: props.payment_guest,
      }).then(async (res) => {
        if (file.value && file.value.file) {
          await caricaFoto(res);
        }
        isLoading.value = false;
        emit("accompagnatoreInserito");
        const objToPush = ref(null);
        if (res.status == 200) {
          if (file.value && file.value.file) {
            const indexOfPoint = file.value.file.name.indexOf(".");
            const strLength = file.value.file.name.length;
            objToPush.value = {
              ...values,
              id_guest: res.data.id,
              guid: res.data.item_id,
              photo: `anagrafiche/${
                res.data.item_id ? res.data.item_id : "default"
              }${
                file.value.file.name
                  ? file.value.file.name.substring(indexOfPoint, strLength)
                  : ".svg"
              }`,
              nominative: `${values.name} ${values.surname}`,
            };
          } else {
            objToPush.value = {
              ...values,
              id_guest: res.data.id,
              guid: res.data.item_id,
              nominative: `${values.name} ${values.surname}`,
            };
          }

          accompagnatoriFromProps.value.push(objToPush.value);
          file.value = null;
        }
      });

      resetForm();
    };
    const accompagnatoriFromProps = ref(
      props.accompagnatori ? props.accompagnatori.results : []
    );
    watch(
      () => props.accompagnatori,
      () => {
        accompagnatoriFromProps.value = props.accompagnatori
          ? props.accompagnatori.results
            ? props.accompagnatori.results
            : props.accompagnatori
          : [];
      }
    );

    const addFotoAcc = ({ values, fileToSend }) => {
      accompagnatoriFromProps.value[values.id_guest] = {
        ...values,
        fileToSend: { ...fileToSend },
        photo: fileToSend.url,
      };

      // document.getElementById(`changeEdit${values.id_guest}`).click();
      emit("accompagnatoreInserito", accompagnatoriFromProps.value);
      return;
    };

    const modificaAcc = async (values) => {
      if (props.from_add_person_to_session) {
        accompagnatoriFromProps.value[values.id] = {
          ...values,
        };

        document.getElementById(`changeEdit${values.id}`).click();
        emit("accompagnatoreInserito", accompagnatoriFromProps.value);
        return;
      }
      // da cambiare in ANAGRAPHICS_PEOPLE_GUEST_EDIT
      await callFunctionAfterAlert(
        API.ANAGRAPHICS_PEOPLE_GUEST_EDIT,
        {
          ...values,
          id_user: props.guid_person,
          id_person: values.id_person,
          id_invitation: props.invito.id,
          id_year: props.invito.id_year,
          overbooking: false,
          id_event: props.invito.id_event,
          self_payment: props.payment_guest,
        },
        `Sei sicuro di voler modificare l'accompagnatore <br> <b>${values.surname} ${values.name}</b>?`,
        `${
          props.id_group
            ? "Accompagnatore modificato con successo"
            : "Accompagnatore creato con successo"
        }`,
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((isAddedorModifiedGroup) => {
        if (isAddedorModifiedGroup) {
          emit("GetAcc");
        }
      });
    };

    const { windowWidth } = useWindowWidth();

    const arraySessioni = ref([]);

    const accompagnatoreInseritoInSessione = (id_guest) => {
      let count = 0;
      if (props.invito.id_status < 4) {
        props.invito.sessions.forEach((session) => {
          if (session.guest) {
            session.guest.forEach((guest) => {
              if (guest.id_guest == id_guest) {
                arraySessioni.value.push(session.name);
                count++;
              }
            });
          }
        });
      } else {
        props.accompagnatori_da_confermare.forEach((session) => {
          if (session.guest) {
            session.guest.forEach((guest) => {
              if (guest.id_guest == id_guest && guest.selected) {
                arraySessioni.value.push(session.name);
                count++;
              }
            });
          }
        });
      }

      return count;
    };

    const eliminaAcc = async (values) => {
      if (props.from_add_person_to_session) {
        accompagnatoriFromProps.value.splice(values.id, 1);

        document.getElementById(`changeEdit${values.id}`).click();
        emit("accompagnatoreInserito", accompagnatoriFromProps.value);
        return;
      }
      const valuesCopy = JSON.parse(JSON.stringify(values));
      if (accompagnatoreInseritoInSessione(values.id_guest)) {
        let text = "";
        arraySessioni.value.forEach((sessione) => {
          text = text + `<li><b>${sessione}</b></li>`;
        });
        alertFailed(
          `L'accompagnatore selezionato è gia presente ${
            arraySessioni.value.length > 1 ? "nelle sessioni" : "nella sessione"
          } :
          <ul>${text}</ul>
          Rimuoverlo da ${
            arraySessioni.value.length > 1
              ? "quelle sessioni"
              : "quella sessione"
          }  prima di continuare
          `
        );
        arraySessioni.value = [];
        return;
      }
      delete valuesCopy.name;
      delete valuesCopy.surname;
      delete valuesCopy.nominative;
      delete valuesCopy.date_of_birth;
      delete valuesCopy.tax_code;
      delete valuesCopy.email;
      delete valuesCopy.phone_number;
      await callFunctionAfterAlert(
        API.INVITATIONS_GUEST_DEL,
        {
          ...valuesCopy,
          id_user: props.guid_person,
          id_person: values.id_person,
          id_invitation: props.invito.id,
          id_year: props.invito.id_year,
          id_event: props.invito.id_event,
          delete_notes: "",
        },
        `Sei sicuro di voler eliminare l'accompagnatore <br> <b>${values.surname} ${values.name}</b>?`,
        `Accompagnatore cancellato con successo`,
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((isAddedorModifiedGroup) => {
        if (isAddedorModifiedGroup) {
          emit("AccEliminato");
          emit("GetAcc");
        }
      });
    };

    const schema = yup.object().shape({
      name: yup.string().required("Inserire il Nome"),
      surname: yup.string().required("Inserire il Cognome"),
      date_of_birth: yup.string().required("Inserire la Data di nascita"),
    });

    const hoverCheckbox = ref(false);

    //FILE
    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(
        e.target.files,
        ".jpg, .jpeg, .png, .gif, .bmp, .tiff, .webp, .svg"
      );
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    const file_controllato = ref(false);
    const errori_txt = ref("");

    const caricaFoto = async (res) => {
      if (!file.value.file) {
        alertFailed(
          "Selezionare un gruppo e caricare un file per procedere con il controllo"
        );
        return;
      }
      file_controllato.value = false;
      errori_txt.value = "";
      isLoading.value = true;
      const bodyFormData = ref(new FormData());
      bodyFormData.value.append("file", file.value.file);
      bodyFormData.value.append("item_id", res.data.item_id);
      bodyFormData.value.append("id_invitation", props.invito.id);
      bodyFormData.value.append("id_event", props.invito.id_event);
      bodyFormData.value.append("id_user", props.invito.id_user);

      await fileSender(API.ANAGRAPHICS_PHOTO_EDIT, bodyFormData.value).then(
        (res) => {
          file_controllato.value = true;
          isLoading.value = false;
          const { status, data } = res;
          if (status != 200) {
            file.value.status = false;
            alertFailed(
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            errori_txt.value =
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi";
          } else {
            file.value.status = true;
            // QUI DA VEDERE COME SARA LA STRUTTURA DEL JSON
            errori_txt.value = data && data.errori ? data.errori : "";

            emit("Refresh");
          }
        }
      );
    };

    const clickLoadFileAccAdd = () => {
      document.getElementById(`addAvatarAccAdd`).click();
    };

    const errors = ref(null);

    const onInvalidSubmit = (v) => {
      console.log(v);
      errors.value = v.errors;
    };

    const id_modal_cropper = ref(3);

    const isOpenModal = ref(false);

    const fileRitagliato = (fileRitagliato) => {
      console.log("fileRitagliato", fileRitagliato);
      file.value.file = fileRitagliato;
    };
    return {
      fileRitagliato,
      isOpenModal,
      id_modal_cropper,
      errors,
      onInvalidSubmit,
      clickLoadFileAccAdd,
      hoverCheckbox,
      schema,
      eliminaAcc,
      windowWidth,
      isLoading,
      accompagnatoriFromProps,
      modificaAcc,
      onSubmit,

      onInputChange,
      file,
      moment,
      INVITI_STATUS,
      addFotoAcc,
    };
  },
};
</script>
