<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add_invitation">
    <div class="modal-dialog">
      <!-- <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading> -->
      <Form
        class="form"
        @invalid-submit="onInvalidSubmit"
        @submit="onSubmit"
        :initial-values="id_invitation ? invitation : formValues"
        :validation-schema="schema"
        v-slot="{ values }"
        autocomplete="off"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ id_invitation ? "Modifica invito" : "Inserimento invito" }}
            </h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_add_invitation_closex"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div
            class="modal-body"
            v-if="
              (id_invitation && loaded_invitation && loaded_guest) ||
              !id_invitation
            "
          >
            <div class="card-body p-6">
              <div class="ps-5 pe-5 repeater-form">
                <div class="row mb-4">
                  <div class="col-md-6">
                    <label class="fw-bold text-gray-700 pe-3">Eventi </label>
                    <Field
                      class="form-select"
                      name="id_event"
                      as="select"
                      disabled
                    >
                      <option
                        v-for="event in lookupEvents"
                        :key="event.id"
                        :value="event.id"
                      >
                        {{ event.label }}
                      </option>
                    </Field>

                    <ErrorMessage v-show="view_errors" name="id_event" />
                  </div>
                  <div class="col-md-6">
                    <label class="fw-bold text-gray-700 pe-3">Anno </label>
                    <Field
                      class="form-select"
                      name="id_year"
                      as="select"
                      disabled
                    >
                      <option
                        v-for="year in lookupYears"
                        :key="year.id"
                        :value="year.id"
                        :selected="year.id == id_year"
                      >
                        {{ year.label }}
                      </option>
                    </Field>

                    <ErrorMessage v-show="view_errors" name="id_year" />
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <label class="fw-bold text-gray-700 pe-3">Gruppo </label>
                    <Field
                      class="form-select"
                      name="select_id_group"
                      as="select"
                      @change="
                        selected_group = $event.target.value
                          ? JSON.parse($event.target.value)
                          : null;
                        selected_people = [];
                        people = [];
                        values.max_sessions = $event.target.value
                          ? selected_group.max_sessions
                          : null;
                        values.id_group = $event.target.value
                          ? selected_group.id
                          : null;
                        // $event.target.value == 3
                        //   ? (values.max_sessions = values.list_length)
                        //   : '';
                        // $event.target.value == 3
                        //   ? (values.payment_invitation = false)
                        //   : '';
                      "
                      :disabled="people.length"
                    >
                      <option :value="null">-</option>
                      <option
                        v-for="event in lookupGroups"
                        :key="event.id"
                        :value="JSON.stringify(event)"
                      >
                        {{ event.label }}
                      </option>
                    </Field>

                    <ErrorMessage v-show="view_errors" name="id_group" />
                  </div>
                  <div class="col-md-4">
                    <label class="fw-bold text-gray-700 pe-3"
                      >Sessioni massime (max. {{ values.list_length }})
                    </label>
                    <Field
                      name="max_sessions"
                      class="form-control"
                      :disabled="values.binding_invitation"
                    />
                    <ErrorMessage v-show="view_errors" name="max_sessions" />
                  </div>
                  <div class="col-md-4">
                    <label class="fw-bold text-gray-700 pe-3"
                      >Massimo accompagnatori per sessione
                    </label>
                    <Field
                      class="form-select"
                      name="max_guest_sessions"
                      :disabled="values.binding_invitation"
                      as="select"
                    >
                      <option :value="0">0</option>
                      <option
                        v-for="number in CONFIG_MAX_GUEST_SESSION"
                        :key="number"
                        :value="number"
                      >
                        {{ number }}
                      </option>
                    </Field>
                    <ErrorMessage
                      v-show="view_errors"
                      name="max_guest_sessions"
                    />
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-4">
                    <label class="fw-bold text-gray-700 pe-3"
                      >Ospite a pagamento
                    </label>
                    <br />
                    <Field
                      name="payment_invitation"
                      type="checkbox"
                      id="payment_invitation"
                      :value="true"
                      :disabled="values.binding_invitation"
                    />

                    <ErrorMessage
                      v-show="view_errors"
                      name="payment_invitation"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="fw-bold text-gray-700 pe-3"
                      >Accompagnatore/i a pagamento
                    </label>
                    <br />
                    <Field
                      name="payment_guest"
                      type="checkbox"
                      id="payment_guest"
                      :value="true"
                      :disabled="
                        !values.max_guest_sessions || values.binding_invitation
                      "
                    />

                    <ErrorMessage v-show="view_errors" name="payment_guest" />
                  </div>
                  <div class="col-md-4">
                    <label class="fw-bold text-gray-700 pe-3"
                      >Invito limitato a due sessioni (dirigenti)
                      <Popper arrow class="ms-2">
                        <button type="button">
                          <i class="bi bi-info-circle-fill text-center"></i>
                        </button>
                        <template #content>
                          <div>
                            Selezionando questa opzione l’invito verrà limitato
                            a massimo due sessioni con invito gratuito solo con
                            selezione di una sessione senza accompagnatori.
                          </div>
                        </template>
                      </Popper>
                    </label>
                    <br />
                    <Field
                      name="binding_invitation"
                      type="checkbox"
                      id="binding_invitation"
                      :value="true"
                      @click="
                        values.payment_guest = false;
                        values.payment_invitation = false;
                        values.max_sessions = 2;
                        CONFIG_MAX_GUEST_SESSION >= 1
                          ? (values.max_guest_sessions = 1)
                          : (values.max_guest_sessions =
                              CONFIG_MAX_GUEST_SESSION);
                      "
                    />

                    <ErrorMessage v-show="view_errors" name="payment_guest" />
                  </div>
                </div>
                <template v-if="!values.id_group">
                  <div class="row pb-4">
                    <div class="col-md-12 filter">
                      <label class="fw-bold text-gray-700 pe-3">Persone</label>
                      <InputList
                        v-if="!id_invitation"
                        :apiLink="API.LOOKUP_PERSON_SEARCH"
                        payloadParameterTextName="surname"
                        payloadParameterTextNameSec="name"
                        payloadParameterTextNameTer="date_of_birth"
                        parameterToShow="nome"
                        @isSelectedElement="
                          values.id_group = null;
                          control_person_includes($event)
                            ? alert_same_person($event)
                            : set_person($event);
                          clickCancellaPersona();
                        "
                        placeholder="Inserire il nominativo nell'apposito campo per popolare la tabella"
                        getEntireRecord
                        :emitEventOnCancel="false"
                        class="mb-4"
                        id_bottone_chiudi="chiudiPersonaAddInvito"
                      />

                      <!-- <input
                        v-else
                        class="form-control"
                        :value="
                          invitation.name +
                          ', ' +
                          invitation.surname +
                          ' - ' +
                          formatDateITA(invitation.date_of_birth)
                        "
                      /> -->

                      <Field
                        type="hidden"
                        name="people"
                        :value="selected_people"
                        class="form-control"
                      />
                      <ErrorMessage v-show="view_errors" name="people" />
                    </div>
                  </div>
                  <Datatable
                    v-if="!values.id_group"
                    :key="people"
                    :table-header="tableHeaderPeople"
                    :table-data="people"
                    :status="status_invitation"
                    :loading="false"
                    :enableItemsPerPageDropdown="false"
                    :total="people.length"
                    :rowsPerPage="100"
                    :currentPage="1"
                    customErrorMessage="Inserire il nominativo nell'apposito campo per popolare la tabella"
                    @sort="
                      $emit('setPayload', {
                        name: 'sortcolumn',
                        value: $event.columnName,
                      });
                      $emit('setPayload', {
                        name: 'sortorder',
                        value: $event.order,
                      });
                      $emit('getList');
                    "
                    @items-per-page-change="
                      $emit('setPayload', {
                        name: 'fetchrows',
                        value: $event,
                      });
                      $emit('getList');
                    "
                    @current-change="
                      currentPage = $event;
                      $emit('setPayload', {
                        name: 'rowstoskip',
                        value: payload.fetchrows * ($event - 1),
                      });
                      $emit('getList');
                    "
                    @resetFilters="$emit('resetFilters')"
                    @checkboxClicked="
                      $emit('checkedElement', objectList.results)
                    "
                  >
                    <template v-slot:cell-id="{ row: data }"
                      ><div class="">
                        {{ data.id }}
                      </div>
                    </template>
                    <template v-slot:cell-name="{ row: data }"
                      ><div class="">
                        {{ data.surname }} {{ data.name }} -
                        {{ data.date_of_birth }}
                      </div>
                    </template>

                    <template v-slot:cell-options="{ index: idx }"
                      ><div class="btn-group">
                        <button
                          class="btn btn-sm dropdown p-1"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          @click="
                            people.splice(idx, 1);
                            selected_people.splice(idx, 1);
                          "
                        >
                          <i class="bi bi-trash text-dark fs-4"></i>
                        </button>
                      </div>
                    </template>
                  </Datatable>
                </template>

                <ErrorMessage v-show="view_errors" name="people" />
                <!--
                    <div class="col-6">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Parcheggio</label
                      >
                      <Field
                        type="checkbox"
                        name="parking"
                        id="active"
                        :value="true"
                        style="margin-top: 2%"
                      />
                    </div>
                    <ErrorMessage v-show="view_errors" name="parking" />
                    <div class="col-6">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Note parcheggio</label
                      >
                      <Field name="parking_notes" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="parking_notes" />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-6">
                      <label class="fw-bold text-gray-700 pe-3">Hotel</label>
                      <Field
                        type="checkbox"
                        name="hotel"
                        id="active"
                        :value="true"
                        style="margin-top: 2%"
                      />
                    </div>
                    <ErrorMessage v-show="view_errors" name="hotel" />
                    <div class="col-6">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Note hotel</label
                      >
                      <Field name="hotel_notes" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="hotel_notes" />
                    </div>
                  </div> -->

                <GuestData
                  v-if="guest && guest.id_guest"
                  :guest="guest"
                  :name_surname_props="`${guest.surname}, ${guest.name}`"
                  :loaded_guest="loaded_guest"
                  :status_guest="status_guest"
                />

                <div class="row pb-4">
                  <label class="fw-bold text-gray-700 pe-3">Sessioni </label>
                  <Field name="list_length" type="hidden" />
                  <SessionsList
                    :key="rerenderSessionList"
                    v-if="!id_invitation"
                    :from_invitations="true"
                    :id_year_from_invitation="values.id_year"
                    :id_event_from_invitation="values.id_event"
                    @checkedElement="set_sessions($event)"
                    @checkedElementLounge="set_sessions($event)"
                    :checked_list_from_invitation="selected_sessions"
                    @listLength="values.list_length = $event"
                  />

                  <Datatable
                    v-else-if="id_invitation && invitation.sessions"
                    :key="loaded_invitation"
                    :table-header="tableHeader"
                    :table-data="invitation.sessions"
                    :status="status_invitation"
                    :loading="!loaded_invitation"
                    :enableItemsPerPageDropdown="false"
                    :total="objectList ? objectList.record : 0"
                    :rowsPerPage="100"
                    :currentPage="1"
                    @sort="
                      $emit('setPayload', {
                        name: 'sortcolumn',
                        value: $event.columnName,
                      });
                      $emit('setPayload', {
                        name: 'sortorder',
                        value: $event.order,
                      });
                      $emit('getList');
                    "
                    @items-per-page-change="
                      $emit('setPayload', {
                        name: 'fetchrows',
                        value: $event,
                      });
                      $emit('getList');
                    "
                    @current-change="
                      currentPage = $event;
                      $emit('setPayload', {
                        name: 'rowstoskip',
                        value: payload.fetchrows * ($event - 1),
                      });
                      $emit('getList');
                    "
                    @resetFilters="$emit('resetFilters')"
                    @checkboxClicked="
                      $emit('checkedElement', objectList.results)
                    "
                  >
                    <template v-slot:cell-id="{ row: data }"
                      ><div class="">
                        {{ data.id_session }}
                      </div>
                    </template>
                    <template v-slot:cell-event="{ row: data }"
                      ><div class="">
                        {{ data.event }}
                      </div>
                    </template>
                    <template v-slot:cell-name="{ row: data }"
                      ><div class="">
                        {{ data.name }}
                      </div>
                    </template>
                    <template v-slot:cell-session_type="{ row: data }"
                      ><div class="">
                        {{ data.session_type }}
                      </div>
                    </template>
                    <template v-slot:cell-description="{ row: data }"
                      ><div class="">{{ data.description }}</div>
                    </template>

                    <template v-slot:cell-options="{ row: data }"
                      ><div class="btn-group">
                        <button
                          class="btn btn-sm dropdown p-1"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_add_session"
                          type="button"
                          @click="selected_id_session = data.id"
                        >
                          <i class="bi bi-pen text-dark fs-4"></i>
                        </button>
                        <button
                          v-if="3 == 4"
                          class="btn btn-sm dropdown p-1"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          @click="
                            deleteElement(
                              data.id,
                              `${data.surname} ${data.name}`
                            )
                          "
                        >
                          <i class="bi bi-trash text-dark fs-4"></i>
                        </button>
                      </div>
                    </template>
                    <template v-slot:cell-checkbox="{ row: data }"
                      ><input
                        type="checkbox"
                        @click="
                          data.checked = $event.target.checked;
                          $emit('checkedElement', objectList.results);
                        "
                      />
                    </template>
                  </Datatable>
                  <Field
                    type="hidden"
                    name="sessions"
                    v-model="selected_sessions"
                  />
                  <ErrorMessage v-show="view_errors" name="sessions" />
                </div>
                <!-- <div class="row pb-4">
                    <label class="fw-bold text-gray-700 pe-3">Sessioni</label>
                    <Multiselect
                      v-model="selected_sessions"
                      :options="lookup_sessions"
                      mode="tags"
                      groupLabel="label"
                      valueProp="id"
                      :close-on-select="false"
                      :searchable="true"
                      :create-option="false"
                    />
                    <Field
                      name="circuiti_secondari"
                      type="hidden"
                      v-model="selectedCircuitiSecondari"
                    />
                    <ErrorMessage v-show="view_errors"
                      v-if="
                        !id_manifestazione ||
                        (id_manifestazione && loadedLookups)
                      "
                      name="circuiti_secondari"
                    />
                  </div> -->
              </div>
              <hr />
            </div>
          </div>
          <Loader v-else />
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_add_invitation_close_button"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-light-success text-success rounded blueFit fs-6"
            >
              Salva ed invia
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import moment from "moment";
import { format } from "@/functions/formatDate";

import { callFunctionAfterAlert, alertFailed } from "@/functions/swAlert";
import { API } from "@/enums/costanti";
import { useApiCall } from "@/composables/apiCall";
import InputList from "@/components/utility/InputList.vue";
import { formatDateITA } from "@/functions/formatDate.js";

import SessionsList from "@/views/SessionsList.vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import GuestData from "@/components/user-sessions/GuestData.vue";
import { useStore } from "vuex";

import Popper from "vue3-popper";

export default {
  name: "add-invitation-modal",
  components: {
    Field,
    ErrorMessage,
    Form,
    InputList,
    // Multiselect,
    SessionsList,
    Datatable,
    GuestData,
    Popper,
  },
  props: {
    id_invitation: {
      type: Number,
    },
    payload: {
      type: Object,
    },
    id_event: {
      type: Object,
    },
    id_year: {
      type: Object,
    },
  },
  emits: ["getList", "rerender"],
  setup(props, { emit }) {
    const view_errors = ref(false);
    const disableButton = ref(false);
    const onInvalidSubmit = (v) => {
      view_errors.value = true;

      alertFailed("Inserire i campi obbligatori");
    };
    const onSubmit = async (values, { resetForm }) => {
      console.log(values);
      const label_persona = values.label_persona;
      delete values.label_persona;
      delete values.select_id_group;
      values.people = JSON.stringify(selected_people.value);
      values.sessions = JSON.stringify(values.sessions);
      values.id_invitation = props.id_invitation;

      await callFunctionAfterAlert(
        props.id_invitation ? API.INVITATIONS_EDIT : API.INVITATIONS_ADD,
        {
          ...values,
          payment_invitation: values.payment_invitation ? true : false,
          payment_guest: values.payment_guest ? true : false,
        },
        `Sei sicuro di voler ${
          props.id_invitation
            ? "modificare l'invito"
            : "inviare l'invito a tutte le persone o al gruppo selezionato"
        } ?`,
        "Invito inviato con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((isAddedorModifiedsession) => {
        if (isAddedorModifiedsession) {
          if (!props.id_invitation) {
            resetForm();
            date_var.value = null;
          }

          rerenderSessionList.value++;
          selected_sessions_str.value = [];
          selected_sessions.value = [];

          document
            .getElementById("kt_modal_add_invitation_close_button")
            .click();
          emit("rerender");
        }
        emit("getList");
      });
    };

    const rerenderSessionList = ref(0);

    const schema = yup.object().shape({
      payment_invitation: yup.boolean(),
      payment_guest: yup.boolean(),
      id_year: yup
        .number()
        .typeError("Inserire un numero")
        .required("Obbligatorio"),
      id_event: yup
        .number()
        .typeError("Inserire un numero")
        .required("Obbligatorio"),
      max_guest_sessions: yup
        .number()
        .typeError("Inserire un numero")
        .required("Obbligatorio"),

      id_group: yup
        .number()
        .typeError("Inserire un numero")
        .nullable()
        .transform((value) => (isNaN(value) ? undefined : value))
        .when(["people"], {
          is: (people) => {
            return !selected_people.value || !selected_people.value.length;
          },
          then: (schema) => schema.required("Obbligatorio"),
        }),
      people: yup.array().of(
        yup.object({
          id_person: yup
            .number()
            .typeError("Inserire un numero")
            .required("Obbligatorio"),
        })
      ),
      // .when(["id_group"], {
      //   is: (id_group) => {

      //     return !id_group;
      //   },
      //   then: (schema) => schema.required("Obbligatorio"),
      // }),
      list_length: yup.number().typeError("Inserire un numero").nullable(),
      max_sessions: yup
        .number()
        .typeError("Inserire un numero")
        .min(1, "Inserire almeno una sessione")
        .test("Max-value", "Sessioni massime superate", (value, context) => {
          const { list_length, max_sessions } = context.parent;
          return max_sessions <= list_length;
        })
        .required("Obbligatorio")
        .typeError("Obbligatorio"),
      sessions: yup
        .array()
        .of(
          yup.object({
            id_session: yup
              .number()
              .typeError("Inserire un numero")
              .required("Obbligatorio"),
          })
        )
        .test({
          message: "Inserire almeno una sessione",
          test: (arr) => arr && arr.length,
        })
        .test(
          "Verifica-lunghezza",
          "Inserire un numero di sessioni maggiore o uguale al numero di sessioni massima inserite",
          (value, context) => {
            const { max_sessions, sessions } = context.parent;

            if (!sessions) return false;
            else return sessions.length >= max_sessions;
          }
        ),
    });

    const date_var = ref(null);

    const payload = ref({ id_invitation: props.id_invitation });

    const {
      element: sessionResults,
      loaded: loaded_invitation,
      getElement: get_invitation,
      status: status_invitation,
    } = useApiCall(API.INVITATIONS_VIEW, payload);

    const { element: lookups, getElement: get_lookups } = useApiCall(
      API.LOOKUP_GET,
      { keys: "ee|yy|gg" }
    );

    get_lookups();

    const payload_guest_view = ref();
    const {
      element: guest_result,
      loaded: loaded_guest,
      getElement: get_guest,
      status: status_guest,
    } = useApiCall(API.INVITATIONS_GUEST_VIEW, payload_guest_view);

    watch(
      () => props.id_invitation,
      async (newVal) => {
        if (newVal) {
          payload.value.id_invitation = newVal;
          await get_invitation();
          payload_guest_view.value = {
            id_person: sessionResults.value.results[0].id_person,
            id_invitation: newVal,
            id_year: sessionResults.value.results[0].id_year,
            id_event: sessionResults.value.results[0].id_event,
            id_user: sessionResults.value.results[0].id_user,
          };

          date_var.value = sessionResults.value.results[0].date;
          await get_guest();
        } else {
          date_var.value = null;
          sessionResults.value = null;
        }
      }
    );

    const formValues = ref({
      id_event: props.id_event,
      id_year: props.id_year,
      max_guest_sessions: 0,
    });

    watch(
      () => props.id_event,
      () => {
        formValues.value = {
          id_event: props.id_event,
          id_year: props.id_year,
        };
      }
    );
    watch(
      () => props.id_year,
      () => {
        formValues.value = {
          id_event: props.id_event,
          id_year: props.id_year,
        };
      }
    );

    const selected_sessions = ref([]);
    const selected_sessions_str = ref([]);

    const set_sessions = (values) => {
      console.log("setsession", values);
      selected_sessions.value = [];
      values.forEach((el) => {
        console.log("set_sessions", el);
        const objToPush = {};
        if (el.lounge) {
          objToPush.lounge = el.lounge;
        }
        if (el.checked) {
          objToPush.id_session = el.id;

          selected_sessions.value.push({
            id_session: el.id,
            lounge: el.lounge ? el.lounge : false,
          });
        }
      });
      console.log(selected_sessions.value);
      selected_sessions_str.value = JSON.stringify(selected_sessions.value);
    };

    const selected_people = ref([]);
    const selected_people_str = ref([]);

    const set_person = (person) => {
      people.value.push(person);
      selected_people.value.push({ id_person: person.id });
      selected_people_str.value = JSON.stringify(selected_people.value);
    };
    const control_person_includes = (person) => {
      return selected_people.value.some((el) => person.id == el.id_person);
    };

    const tableHeader = ref([
      {
        name: "Nome",
        key: "name",
      },
      {
        name: "Descrizione",
        key: "description",
      },

      {
        name: "Tipo di sessione",
        key: "session_type",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);
    const tableHeaderPeople = ref([
      {
        name: "Nome",
        key: "name",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const people = ref([]);

    const add_person_to_people = (e) => {
      people.value.push(e);
    };

    const deleteElement = (idx) => {
      people.value.splice(idx, 1);
    };

    const alert_same_person = (e) => {
      console.log(e);
      alertFailed(
        `La persona <b>${e.name} ${e.surname}</b> è gia stata inserita`
      );
    };

    const clickCancellaPersona = () => {
      document.getElementById("chiudiPersonaAddInvito").click();
    };

    const store = useStore();

    return {
      control_person_includes,
      clickCancellaPersona,
      alert_same_person,
      deleteElement,
      add_person_to_people,
      people,
      tableHeaderPeople,
      selected_sessions_str,
      selected_people,
      set_sessions,
      set_person,
      selected_sessions,
      formValues,
      lookups,
      sessionResults,
      loaded_invitation,
      status_invitation,
      moment,
      format,
      date_var,
      schema,
      onSubmit,
      onInvalidSubmit,
      disableButton,
      API,
      formatDateITA,
      tableHeader,
      guest_result,
      loaded_guest,
      status_guest,
      view_errors,
      rerenderSessionList,
      CONFIG_MAX_GUEST_SESSION: computed(
        () => store.getters.CONFIG_MAX_GUEST_SESSION
      ),
      loadedConfigurazioni: computed(() => store.getters.loadedConfigurazioni),
    };
  },
  computed: {
    lookupYears() {
      return this.lookups && this.lookups.result && this.lookups.result[1]
        ? this.lookups.result[1].years
        : [];
    },
    lookupEvents() {
      return this.lookups && this.lookups.result && this.lookups.result[0]
        ? this.lookups.result[0].events
        : [];
    },
    lookupSessionType() {
      return this.sessionTypeResults && this.sessionTypeResults.results
        ? this.sessionTypeResults.results
        : [];
    },
    lookupGroups() {
      return this.lookups && this.lookups.result && this.lookups.result[2]
        ? this.lookups.result[2].groups
        : [];
    },
    invitation() {
      return this.id_invitation && this.sessionResults
        ? this.sessionResults.results[0]
        : {};
    },
    guest() {
      return this.id_invitation && this.guest_result
        ? this.guest_result.results[0]
        : {};
    },
  },
};
</script>

<style scoped>
.modal-content {
  width: 150%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
    .border-active
  ):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
ErrorMessage {
  color: red;
}
</style>
