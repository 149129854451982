<template>
  <Form
    @invalid-submit="onInvalidSubmit"
    @submit="onSubmit"
    :initial-values="sessione"
    :validation-schema="schema"
    autocomplete="off"
    class="card shadow mt-3"
    :class="windowWidth <= 767 ? '' : 'me-2'"
    v-slot="{ values }"
  >
    <!-- #357ffa -->
    <div class="card-header px-3">
      <div class="my-2 d-flex w-100 justify-content-between text-white">
        <div class="fw-bold">{{ sessione.description }}</div>
        <div class="fw-bold">
          {{ moment(sessione.date).format("DD/MM/YYYY HH:mm") }}
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-6 d-flex p-0">
          <Field type="hidden" name="lounge" />
          <Field
            name="selected"
            class="align-self-start mt-2 me-2"
            type="checkbox"
            :value="true"
            :checked="values.selected"
            @change="
              $event.target.checked ? '' : (selectedAccompagnatori = []);
              $event.target.checked ? '' : (values.selected_guest = false);
              $event.target.checked
                ? ''
                : $emit('lastLoungeSelected', values.lounge);
              $event.target.checked
                ? (values.lounge = values.lounge
                    ? values.lounge
                    : valoreLoungeSelezionataUltimaSessione)
                : (values.lounge = false);
              onSubmit(values);
            "
            @click="nonAggiungereASessioniSelezionate = false"
            :disabled="
              !puoModificareInvito ||
              (invito.max_sessions == numberSessioniOspiteSelected &&
                !values.selected) ||
              !canEnterSession(sessione.date) ||
              sessione.collaborator_selection_block
            "
          />
          <label class="fw-500 fs-7">Partecipo a questa sessione </label>

          <ErrorMessage v-show="view_errors" name="can_invitation_send" />
        </div>
        <div class="col-6 d-flex p-0">
          <!-- REGOLA GIUNTA
            (invito.id_group == 71 &&
                (values.id_session == 5 || values.id_session == 7)) -->
          <!-- (invito.id_group == 70 &&
                (values.id_session == 3 ||
                  values.id_session == 5 ||
                  values.id_session == 7)) -->
          <Field
            name="selected_guest"
            id="selected_guest_id"
            class="align-self-start mt-2 me-2"
            type="checkbox"
            :value="true"
            :checked="values.selected_guest || selectedAccompagnatori.length"
            :disabled="
              !values.selected ||
              (values.selected && !accompagnatoriOptions.length) ||
              !puoModificareInvito ||
              (invito.max_sessions == numberSessioniOspiteSelected &&
                !values.selected) ||
              !canEnterSession(sessione.date) ||
              !puoModificareInvito ||
              sessione.collaborator_selection_block
            "
            v-model="selectedGuest"
            @click="
              nonAggiungereASessioniSelezionate = true;
              onSubmit(values);
            "
            @change="
              $event.target.checked ? '' : (values.selected_guest = false)
            "
          />
          <label class="fw-500 fs-7">Ho un accompagnatore</label>

          <ErrorMessage v-show="view_errors" name="can_invitation_send" />
        </div>
      </div>
      <div class="row">
        <div style="min-height: 1.5rem">
          <ErrorMessage name="guest" />
        </div>
        <Multiselect
          :key="accompagnatoriOptions.length"
          v-model="selectedAccompagnatori"
          :options="accompagnatoriOptions"
          class="p-0"
          mode="tags"
          groupLabel="label"
          :placeholder="
            accompagnatoriOptions
              ? 'Scegli accompagnatori tra quelli in elenco'
              : 'Nessun accompagnatore disponibile'
          "
          label="nominative"
          valueProp="id_guest"
          :close-on-select="false"
          :searchable="true"
          :create-option="false"
          :disabled="
            !values.selected_guest ||
            (invito.max_sessions == numberSessioniOspiteSelected &&
              !values.selected) ||
            !canEnterSession(sessione.date) ||
            !puoModificareInvito ||
            sessione.collaborator_selection_block
          "
          :max="invito.max_guest_sessions"
        />
        <Field name="guest" type="hidden" v-model="selectedAccompagnatori" />

        <button
          type="button"
          :id="`submit${sessione.id_session}`"
          class="d-none"
          @click="onSubmit(values)"
        ></button>
        <!-- <ErrorMessage v-show="view_errors" name="surname" /> -->
      </div>
      <div class="mb-3 mt-1">
        <!-- {{ accompagnatoriOptions }} -->
        <span
          class="fw-bold text-success"
          v-if="values.lounge && invito.id_status >= 4 && 1 == 2"
          ><template v-if="sessione.guest && sessione.guest.length"
            >La informiamo che lei
            <b>
              {{
                sessione.guest.length === 1 && sessione.guest[0].lounge
                  ? `e ${
                      sessione.guest[0].surname
                        ? sessione.guest[0].surname
                        : getAccompagnatoreSurnameFromIdGuest(sessione.guest[0])
                    } ${
                      sessione.guest[0].name
                        ? sessione.guest[0].name
                        : getAccompagnatoreNameFromIdGuest(sessione.guest[0])
                    }`
                  : sessione.guest.length === 1 && !sessione.guest[0].lounge
                  ? `avrà accesso alla lounge durante la
            sessione.`
                  : sessione.guest.length === 2 &&
                    sessione.guest[0].lounge &&
                    sessione.guest[1].lounge
                  ? `,${
                      sessione.guest[0].surname
                        ? sessione.guest[0].surname
                        : getAccompagnatoreSurnameFromIdGuest(sessione.guest[0])
                    } ${
                      sessione.guest[0].name
                        ? sessione.guest[0].name
                        : getAccompagnatoreNameFromIdGuest(sessione.guest[0])
                    } e ${
                      sessione.guest[1].surname
                        ? sessione.guest[1].surname
                        : getAccompagnatoreSurnameFromIdGuest(sessione.guest[1])
                    } ${
                      sessione.guest[1].name
                        ? sessione.guest[1].name
                        : getAccompagnatoreNameFromIdGuest(sessione.guest[1])
                    }`
                  : sessione.guest.length === 2 &&
                    !sessione.guest[0].lounge &&
                    sessione.guest[1].lounge
                  ? ` e ${
                      sessione.guest[1].surname
                        ? sessione.guest[1].surname
                        : getAccompagnatoreSurnameFromIdGuest(sessione.guest[1])
                    } ${
                      sessione.guest[1].name
                        ? sessione.guest[1].name
                        : getAccompagnatoreNameFromIdGuest(sessione.guest[1])
                    }`
                  : sessione.guest.length === 2 &&
                    sessione.guest[0].lounge &&
                    !sessione.guest[1].lounge
                  ? ` e ${
                      sessione.guest[0].surname
                        ? sessione.guest[0].surname
                        : getAccompagnatoreSurnameFromIdGuest(sessione.guest[0])
                    } ${
                      sessione.guest[0].name
                        ? sessione.guest[0].name
                        : getAccompagnatoreNameFromIdGuest(sessione.guest[0])
                    }`
                  : sessione.guest
                      .slice(0, -1)
                      .map((guest) =>
                        guest.lounge ? `${guest.surname} ${guest.name}` : ""
                      )
                      .join(", ") +
                    " e " +
                    `${
                      sessione.guest[sessione.guest.length - 1].surname
                        ? sessione.guest[sessione.guest.length - 1].surname
                        : getAccompagnatoreSurnameFromIdGuest(
                            sessione.guest[sessione.guest.length - 1]
                          )
                    } ${
                      sessione.guest[sessione.guest.length - 1].name
                        ? sessione.guest[sessione.guest.length - 1].name
                        : getAccompagnatoreSurnameFromIdGuest(
                            sessione.guest[sessione.guest.length - 1]
                          )
                    }`
              }}
            </b>
            {{
              sessione.guest.length === 1 && !sessione.guest[0].lounge
                ? ""
                : ", avrete accesso alla lounge durante la sessione."
            }}</template
          >
          <template v-else>
            La informiamo che avrà accesso alla lounge durante la
            sessione.</template
          >
        </span>
        <br v-else />
      </div>
    </div>
  </Form>
</template>
<script>
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  computed,
} from "vue";
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import { useWindowWidth } from "@/composables/getWindowWidth";
import { INVITI_STATUS } from "@/enums/costanti";
import * as yup from "yup";
import { alertFailed } from "@/functions/swAlert";
export default defineComponent({
  components: {
    Multiselect,
    Form,
    Field,
    ErrorMessage,
  },
  emits: ["single_session", "lastLoungeSelected"],
  props: {
    sessione: {
      type: Object,
    },
    accompagnatori: {
      type: Array,
    },
    invito: {
      type: Array,
    },
    numberSessioniOspiteSelected: {
      type: Number,
    },
    isConsigliere: {
      type: Boolean,
    },
    valoreLoungeSelezionataUltimaSessione: {
      type: Boolean,
      default: false,
    },
    consigliereStaModificando: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectedAccompagnatori = ref([]);
    const selectedGuest = ref(false);

    if (props.sessione && props.sessione.guest && props.sessione.guest.length) {
      props.sessione.guest.forEach((element) => {
        selectedAccompagnatori.value.push(element.id_guest);
      });
      selectedGuest.value = true;
    }

    const nameSurname = ({ name, surname }) => {
      return `${name} ${surname}`;
    };
    const { windowWidth } = useWindowWidth();

    watch(
      selectedAccompagnatori,
      () => {
        console.log("watch selectedAccompagnatori");
        if (
          selectedAccompagnatori.value.length <= props.invito.max_guest_sessions
        ) {
          document.getElementById(`submit${props.sessione.id_session}`).click();
        }
        if (
          selectedAccompagnatori.value.length &&
          props.invito.id_status >= INVITI_STATUS.DA_CONFERMARE
        ) {
          document.getElementById("selected_guest_id").checked = true;
        }
        // console.log(
        //   `submit${props.sessione.id_session}`,
        //   document.getElementById(`submit${props.sessione.id_session}`)
        // );
        // document.getElementById(`submit${props.sessione.id_session}`).click();
      },
      { deep: true }
    );

    const nonAggiungereASessioniSelezionate = ref(false);

    const onSubmit = (values) => {
      console.log("onSubmit singola sessione");
      emit("single_session", {
        values,
        selectedAccompagnatori: selectedAccompagnatori.value,
        nonAggiungereASessioniSelezionate:
          nonAggiungereASessioniSelezionate.value,
      });
      // nonAggiungereASessioniSelezionate.value = false;
    };

    watch(selectedGuest, (val) => {
      if (!val) {
        selectedAccompagnatori.value = [];
      }
    });
    const clickFromPartecipo = ref(false);

    const canEnterSession = (sessionStart) => {
      const now = new Date();
      const sessionStartDate = new Date(sessionStart);
      const twelveHoursAfterSession = new Date(
        sessionStartDate.getTime() + 12 * 60 * 60 * 1000
      );
      // sessionStartDate.getTime() - 24 * 60 * 60 * 1000
      return now < twelveHoursAfterSession;
      // return new Date(date) < new Date();
    };
    const schema = yup.object().shape({
      guest: yup
        .array()
        .test(
          "Numero di invitati",
          "E' stato raggiunto il numero massimo di accompagnatori autorizzati per questa sessione",
          (value, context) => {
            console.log(
              selectedAccompagnatori.value.length ==
                props.invito.max_guest_sessions,
              selectedAccompagnatori.value.length,
              props.invito.max_guest_sessions
            );
            return !(
              selectedAccompagnatori.value.length ==
              props.invito.max_guest_sessions
            );
          }
        ),
    });

    // onMounted(() => {
    //   document.addEventListener("click", gestioneClick);
    // });
    // onBeforeUnmount(() => {
    //   document.removeEventListener("click", gestioneClick);
    // });
    // const gestioneClick = (event) => {
    //   console.log(event);
    //   if (event.target.classList.contains("multiselect-tags")) {
    //     selectedAccompagnatori.value.length == props.invito.max_guest_sessions
    //       ? alertFailed(
    //           `E' stato raggiunto il numero massimo di accompagnatori per questa sessione`
    //         )
    //       : "";
    //   }
    // };

    const accompagnatoriOptions = computed(() => {
      let arr = [];
      if (props.accompagnatori && props.accompagnatori.results) {
        props.accompagnatori.results.forEach((el) => {
          el.jsonValue = { id_guest: el.id_guest };
          arr.push(el);
        });
      }

      return arr;
    });

    const getAccompagnatoreNameFromIdGuest = (guest) => {
      let name = "";
      accompagnatoriOptions.value.forEach((acc) => {
        console.log("getAccompagnatoreNameFromIdGuest", acc, guest);
        if (acc.id_guest == guest.id_guest) {
          name = acc.name;
        }
      });
      return name;
    };
    const getAccompagnatoreSurnameFromIdGuest = (guest) => {
      let surname = "";
      accompagnatoriOptions.value.forEach((acc) => {
        if (acc.id_guest == guest.id_guest) {
          surname = acc.surname;
        }
      });
      return surname;
    };
    return {
      getAccompagnatoreSurnameFromIdGuest,
      getAccompagnatoreNameFromIdGuest,
      schema,
      alertFailed,
      canEnterSession,
      nonAggiungereASessioniSelezionate,
      clickFromPartecipo,
      selectedGuest,
      onSubmit,
      moment,
      selectedAccompagnatori,
      nameSurname,
      windowWidth,
      INVITI_STATUS,
      accompagnatoriOptions,
    };
  },
  computed: {
    accompagnatoriConAccessoLounge() {
      const guestsWithLounge = this.sessione.guest.filter((accompagnatore) => {
        return accompagnatore.lounge === true;
      });
      const numberOfGuestsWithLounge = guestsWithLounge.length;
      return numberOfGuestsWithLounge;
    },

    puoModificareInvito() {
      return (
        this.invito.id_status < INVITI_STATUS.DA_CONFERMARE ||
        (this.invito.id_status == INVITI_STATUS.CONFERMATO &&
          this.invito.can_invitation_send)
      );
    },
  },
});
</script>
<style>
.card-header {
  background-color: #357ffa !important;
  min-height: auto !important;
  /* min-width: 350px; */
}
.multiselect-placeholder {
  font-size: small;
}
</style>
