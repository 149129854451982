<template>
  <div>
    <div
      class="row mt-5"
      v-if="!from_invitations && !from_invitations_consigliere"
    >
      <div class="col-md-4">
        <div>
          <!-- <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_session"
            @click="selected_id_session = null"
            :disabled="!id_year || !id_event"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi sessione
          </button> -->
          <ModalAddSession
            :id_session="selected_id_session"
            :payload="payload"
            :id_event="id_event"
            :id_year="id_year"
            @getList="$emit('getList')"
          />
          <ModalAddPersonToSession
            :id_session="selected_id_session"
            :payload="payload"
            :id_event="id_event"
            :id_year="id_year"
            :selected_session_name="selected_session_name"
            @getList="$emit('getList')"
          />
          <ModalPrintPerson
            :id_session="selected_id_session_print"
            :payload="payload"
            :id_event="id_event"
            :id_year="id_year"
            :selected_session_name="selected_session_name"
            @getList="$emit('getList')"
          />
        </div>
      </div>
      <div class="col-md-4">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6 text-start"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_add_person_to_session"
          @click="selected_id_session = null"
          :disabled="!id_year || !id_event"
        >
          <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi nuova anagrafica
          a sessioni
        </button>
      </div>
      <div class="col-md-4">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="objectListFromProps && objectListFromProps.record > 0">
            {{
              payload.rowstoskip + payload.fetchrows - (payload.fetchrows - 1)
            }}
            -
            {{
              payload.rowstoskip + payload.fetchrows >
              objectListFromProps.record
                ? objectListFromProps.record
                : payload.rowstoskip + payload.fetchrows
            }}
          </b>
          <b v-else> 0 </b>
          sessioni di
          <b>{{
            objectListFromProps && objectListFromProps.record
              ? objectListFromProps.record
              : 0
          }}</b>
        </div>
      </div>
    </div>
    <br />
    <!-- :key="rerenderDatatable" -->

    <template v-if="!from_invitations_consigliere">
      <template v-if="!payload.id_year || !payload.id_event">
        <div
          class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
        >
          <span class="text-gray-700 ps-3 fs-2">
            Inserire anno ed evento per vedere le sessioni disponibili
            {{ from_invitations ? "" : "ed inserirne di nuove" }}
          </span>
        </div>
      </template>
      <Datatable
        v-else
        :key="loaded"
        :table-header="
          from_invitations
            ? tableHeaderInvitation
            : from_invitations_consigliere
            ? tableHeaderInvitationConsigliere
            : tableHeader
        "
        :table-data="
          objectList && objectListFromProps ? objectListFromProps.results : []
        "
        :status="status"
        :loading="!loaded"
        :enableItemsPerPageDropdown="
          !from_invitations && !from_invitations_consigliere
        "
        :total="objectListFromProps ? objectListFromProps.record : 0"
        :rowsPerPage="payload.fetchrows"
        :currentPage="currentPage"
        :order="payload.sortorder"
        :sortLabel="payload.sortcolumn"
        :table-header2="tableHeader2"
        @sort="
          $emit('setPayload', {
            name: 'sortcolumn',
            value: $event.columnName,
          });
          $emit('setPayload', {
            name: 'sortorder',
            value: $event.order,
          });
          $emit('getList');
        "
        @items-per-page-change="
          $emit('setPayload', {
            name: 'fetchrows',
            value: $event,
          });
          $emit('getList');
        "
        @current-change="
          currentPage = $event;
          $emit('setPayload', {
            name: 'rowstoskip',
            value: payload.fetchrows * ($event - 1),
          });
          $emit('getList');
        "
        @resetFilters="$emit('resetFilters')"
        @checkboxClicked="
          $emit('checkedElement', objectListFromProps.results);
          setChecked($event.target.checked);
        "
      >
        <template v-slot:cell-id="{ row: data }"
          ><div class="">
            {{ data.id }}
          </div>
        </template>
        <template v-slot:cell-event="{ row: data }"
          ><div class="">
            {{ data.event }}
          </div>
        </template>
        <template v-slot:cell-name="{ row: data }"
          ><div class="">
            {{ data.name }}
          </div>
        </template>
        <template v-slot:cell-session_type="{ row: data }"
          ><div class="">
            {{ data.session_type }}
          </div>
        </template>
        <template v-slot:cell-date="{ row: data }"
          ><div class="tab-long">
            {{ formatDateITA(data.date) }}
          </div>
        </template>
        <template v-slot:cell-description="{ row: data }"
          ><div class="">
            {{ data.description }}
          </div>
        </template>
        <!-- <template v-slot:cell-checkbox="{ row: data }"
        ><div class="">
          {{ data.description }}
        </div>
      </template> -->
        <template v-slot:cell-n_person="{ row: data }">
          <Popper>
            <i class="bi bi-info-circle-fill text-center cursor-pointer"></i>
            <template #content>
              <div>
                <div :style="`color: rgb(205, 80, 80);`" class="mb-2">
                  <div class="d-flex w-100">
                    <div class="min-wid-50">
                      {{ data.n_person_confirm_admin_pending }}
                    </div>
                    <div class="text-black-light">
                      Persone da confermare dall'amministratore
                    </div>
                  </div>
                  <!-- // da confermare maria non cf -->
                </div>
                <div :style="`color: rgb(46, 130, 166);`" class="mb-2">
                  <div class="d-flex w-100">
                    <div class="min-wid-50">
                      {{ data.n_person_confirm_cf_pending }}
                    </div>

                    <div class="text-black-light">
                      PERSONE DA CONFERMARE CONSIGLIERI FEDERALI
                    </div>
                  </div>

                  <!-- // da confermare maria  cf  -->
                </div>
                <div class="mb-2" :style="`color: rgb(0, 158, 247);`">
                  <div class="d-flex w-100">
                    <div class="min-wid-50">
                      {{ data.n_person_payment_pending }}
                    </div>

                    <div class="text-black-light">
                      NUMERO DI PERSONE CONFERMATE MA IN ATTESA DI PAGAMENTO
                    </div>
                  </div>
                  <!-- // da pagare -->
                </div>
                <div class="mb-2" :style="`color: rgb(46, 139, 87);`">
                  <div class="d-flex w-100">
                    <div class="min-wid-50">
                      {{ data.n_person_seat_confirmed }}
                    </div>

                    <div class="text-black-light">
                      NUMERO DI PERSONE CONFERMATE
                    </div>
                  </div>
                  <!-- // sedute confermate -->
                </div>
                <div :style="`color: ${people_color(data.perc)}`" class="mb-2">
                  <div class="d-flex w-100">
                    <div class="min-wid-50">
                      {{ data.n_person_lounge }}
                    </div>

                    <!-- // totale -->
                    <div class="text-black-light">PERSONE IN LOUNGE</div>
                  </div>
                </div>
                <div :style="`color: ${people_color(data.perc)}`" class="mb-2">
                  <div class="d-flex w-100">
                    <div class="min-wid-50">{{ data.max_projection }}</div>

                    <!-- // totale -->
                    <div class="text-black-light">PROIEZIONE TOTALE</div>
                  </div>
                </div>
              </div>
            </template>
          </Popper>
        </template>
        <template v-slot:cell-checkbox="{ row: data, data: dati }"
          ><input
            type="checkbox"
            @click="
              data.checked = $event.target.checked;
              $emit('checkedElement', dati);
            "
            :checked="data.checked"
            :id="`checkbox_session${data.id}`"
            v-if="!(disable_semifinals_finals && data.id_session_type >= 3)"
            :disabled="
              selected_sessions_length == 2 && is_ospite && !data.checked
            "
          />
          <div v-else></div>
        </template>
        <template v-slot:cell-checkbox_active="{ row: data }"
          ><div class="">
            <input
              :key="data.active"
              type="checkbox"
              name="active"
              id="active"
              :value="true"
              :checked="data.active"
              @click="propose_change_session(data, $event)"
              style="margin-top: 2%"
            />
          </div>
        </template>
        <template v-slot:cell-number_of_prints="{ row: data }">
          <div class="d-flex justify-content-evenly">
            <div
              class=""
              @click="scaricaSessioniCSV(data.id)"
              style="cursor: pointer; color: black"
            >
              <i class="bi bi-filetype-csv fs-4 text-black"></i>
            </div>

            <div
              class=""
              style="cursor: pointer"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_print_person_from_session"
              @click="
                selected_id_session_print = data.id;
                selected_session_name = data.name;
              "
            >
              <i class="bi bi-chat-left-text text-dark fs-4"></i>
            </div>
          </div>
        </template>
        <template v-slot:cell-checkbox_selezionato="{ row: data, data: dati }">
          <div class="">
            <!-- :disabled="
              max_sessions_dirigente_ospite == selected_sessions_length &&
              !data.selezionato
            " -->
            <input
              type="checkbox"
              name="selezionato"
              id="selezionato"
              :value="true"
              :checked="data.selezionato"
              @click="
                data.selezionato = $event.target.checked;
                $emit('checkedElementSelezionato', dati);
              "
              style="margin-top: 2%"
              :disabled="
                max_session_invitation_send_reached && !data.selezionato
              "
            />
          </div>
        </template>

        <template v-slot:cell-options="{ row: data }"
          ><div class="btn-group">
            <button
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#TorneiRaas_panel_${data.id}`"
              aria-expanded="true"
              :aria-controls="`#TorneiRaas_panel_${data.id}`"
              v-if="accompagnatori && accompagnatori.length"
            >
              <i class="bi bi-person-down text-dark fs-4"></i>
            </button>
            <button
              class="btn btn-sm dropdown p-1"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_add_session"
              type="button"
              @click="selected_id_session = data.id"
            >
              <i class="bi bi-pen text-dark fs-4"></i>
            </button>
          </div>
        </template>
        <template
          v-slot:cell-accompagnatori="{ row: data, index: idx, data: dati }"
        >
          <div>
            <div
              :id="`TorneiRaas_panel_${data.id}`"
              class="accordion-collapse collapse"
              :aria-labelledby="`TorneiRaas_int_${data.id}`"
              :style="
                idx % 2 == 0
                  ? 'background-color: #f8fafb'
                  : 'background-color: #ffffff'
              "
            >
              <div class="accordion-body">
                <table class="table tablegy-3 gs-7 align-top">
                  <thead>
                    <tr
                      class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                    >
                      <th>Selezionato</th>
                      <th>Cognome</th>
                      <th>Nome</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(guest, index) in accompagnatori" :key="index">
                      <td>
                        <input
                          :name="`selected_guest_session${data.id}`"
                          class="align-self-start me-2"
                          type="checkbox"
                          :checked="
                            data.guests && data.guests[index].selected
                              ? true
                              : false
                          "
                          :disabled="!data.selezionato"
                          @change="
                            data.guests = data.guests ? data.guests : [];
                            data.guests.push(guest);

                            data.guests[index].selected =
                              data.guests && data.guests[index].selected
                                ? true
                                : !data.guests[index].selected;

                            $emit('checkedElementSelezionato', dati);
                          "
                        />
                      </td>
                      <td>{{ guest.surname }}</td>
                      <td>{{ guest.name }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class=""></div>
              </div>
            </div>
          </div>
        </template>
      </Datatable>
    </template>

    <div class="row p-0 mx-auto" v-else-if="objectList && objectListFromProps">
      <div
        class="col-12 col-md-6 mb-4 px-0"
        v-for="sessione in objectListFromProps.results"
        :key="sessione.id_session"
      >
        <SingolaSessioneConsigliere
          :sessione="sessione"
          :accompagnatori="accompagnatori"
          @single_session="
            // $emit('single_session', {
            //   values: $event.values,
            //   selectedAccompagnatori: $event.selectedAccompagnatori,
            //   nonAggiungereASessioniSelezionate:
            //     $event.nonAggiungereASessioniSelezionate,
            // });

            sessione.selezionato = $event.values.selected;
            sessione.selected = $event.values.selected;
            sessione.guest = $event.selectedAccompagnatori;
            sessione.lounge = $event.values.lounge
              ? $event.values.lounge
              : false;
            $emit('checkedElementSelezionato', objectListFromProps.results);
          "
          :numberSessioniOspiteSelected="numberSessioniOspiteSelected"
          :disabled="
            max_session_invitation_send_reached && !sessione.selezionato
          "
          :from_invitations="from_invitations"
          :from_invitations_consigliere="from_invitations_consigliere"
          :can_set_lounge="can_set_lounge"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Datatable from "@/components/kt-datatable/KTDatatableSottosezione.vue";
import ModalAddSession from "./ModalAddSession.vue";
import ModalAddPersonToSession from "./ModalAddPersonToSession.vue";
import ModalPrintPerson from "./ModalPrintPerson.vue";

import { ref, watch } from "vue";
import { callFunctionAfterAlert } from "@/functions/swAlert";
import { API } from "@/enums/costanti";
import { formatDateITA } from "@/functions/formatDate.js";
import Popper from "vue3-popper";

import SingolaSessioneConsigliere from "@/components/invitations/SingolaSessioneConsigliere.vue";

export default {
  name: "TableTornei",
  components: {
    SingolaSessioneConsigliere,
    Datatable,
    ModalAddSession,
    ModalAddPersonToSession,
    Popper,
    ModalPrintPerson,
  },
  emits: [
    "getList",
    "resetFilters",
    "setPayload",
    "checkedElement",
    "checkedElementSelezionato",
    "checkedElementAccompagnatore",
  ],
  props: {
    objectList: {
      type: Object,
    },
    loaded: {
      type: Boolean,
    },
    status: {
      type: Number,
    },
    payload: {
      type: Object,
    },
    pressedSearch: {
      type: Boolean,
    },
    id_year: {
      type: Number,
    },
    id_event: {
      type: Number,
    },
    from_invitations: {
      type: Boolean,
    },
    from_invitations_consigliere: {
      type: Boolean,
    },
    disable_semifinals_finals: { type: Boolean },
    is_ospite: { type: Boolean },
    selected_sessions_length: { type: Number },

    max_sessions_dirigente_ospite: {
      type: Number,
    },
    accompagnatori: {
      type: Array,
    },
    max_session_invitation_send_reached: {
      type: Boolean,
    },
    can_set_lounge: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const tableHeader = ref([
      {
        name: "Nome",
        key: "name",
      },
      {
        name: "Evento",
        key: "event",
      },
      {
        name: "Tipologia sessione",
        key: "session_type",
      },
      {
        name: "Data e Ora",
        key: "date",
      },
      {
        name: "Descrizione",
        key: "description",
      },
      {
        name: "Attivo",
        key: "checkbox_active",
        sortable: false,
      },
      {
        name: "Stampa",
        key: "number_of_prints",
        sortable: false,
      },
      {
        name: "Numero persone",
        key: "n_person",
        sortable: false,
      },

      {
        key: `${props.from_invitations ? "checkbox" : "options"}`,
        sortable: false,
      },
    ]);

    const tableHeaderInvitation = ref([
      {
        name: "Nome",
        key: "name",
      },
      {
        name: "Evento",
        key: "event",
      },
      {
        name: "Tipologia sessione",
        key: "session_type",
      },
      {
        name: "Data e Ora",
        key: "date",
      },
      {
        name: "Descrizione",
        key: "description",
      },

      {
        key: `${props.from_invitations ? "checkbox" : "options"}`,
        sortable: false,
      },
    ]);
    const tableHeaderInvitationConsigliere = ref([
      {
        key: "checkbox_selezionato",
        name: "Selezionato",
        sortable: false,
      },

      {
        name: "Nome",
        key: "name",
      },
      {
        name: "Evento",
        key: "event",
      },
      {
        name: "Tipologia sessione",
        key: "session_type",
      },
      {
        name: "Data e Ora",
        key: "date",
      },
      {
        name: "Descrizione",
        key: "description",
      },
    ]);

    const deleteElement = async (id_session, session_name) => {
      await callFunctionAfterAlert(
        API.SESSIONS_DEL,
        { id_session },
        `Sei sicuro di voler cancellare <b>${session_name}</b>?`,
        "Sessionre cancellata con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((deletedElement) => {
        if (deletedElement) {
          emit("getList");
        }
      });
    };

    const selected_id_session = ref(0);
    const selected_id_session_del = ref(0);
    const selected_id_session_print = ref(0);

    const currentPage = ref(1);

    const propose_change_session = async (data, event) => {
      event.preventDefault();
      await callFunctionAfterAlert(
        API.SESSIONS_ACTIVE_EDIT,
        {
          id_session: data.id,
          id_year: props.id_year,
          id_event: props.id_event,
        },
        `Continuando verrà cambiata la sessione attiva.`,
        "Sessione attiva cambiata con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((changedSession) => {
        if (changedSession) {
          emit("getList");
        } else {
          // event.preventDefault();
        }
      });
    };
    const propose_change_overbooking_session = async (data, event) => {
      event.preventDefault();
      await callFunctionAfterAlert(
        API.SESSIONS_OVERBOOKING_EDIT,
        {
          id_session: data.id,
          id_year: props.id_year,
          id_event: props.id_event,
        },
        `Continuando verrà cambiata la gestione dell'overbooking nella sessione.`,
        "Overbooking della sessione modificato con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((changedSession) => {
        if (changedSession) {
          emit("getList");
        } else {
          // event.preventDefault();
        }
      });
    };

    const selected_session_name = ref("");

    const people_color = (perc) => {
      return perc < 0.5 ? "#24c300" : perc < 0.9 ? "blue" : "red";
    };

    const objectListFromProps = ref({ ...props.objectList });
    watch(
      () => props.objectList,
      () => {
        objectListFromProps.value = { ...props.objectList };
      }
    );

    const setChecked = (isChecked) => {
      objectListFromProps.value.results.forEach((element, idx) => {
        if (props.is_ospite) {
          if (element.checked != isChecked && idx < 2) {
            element.checked = isChecked;
            const checkbox = document.getElementById(
              `checkbox_session${element.id}`
            );
            if (checkbox) checkbox.click();
          }
        } else {
          if (element.checked != isChecked) {
            element.checked = isChecked;
            const checkbox = document.getElementById(
              `checkbox_session${element.id}`
            );
            if (checkbox) checkbox.click();
          }
        }
      });
    };

    const scaricaSessioniCSV = (id_session) => {
      if (props.payload.id_year && props.payload.id_event) {
        // props.payload.id_status ? 4 : ""
        const url = `${
          process.env.VUE_APP_IS_BRANCH_PROD == 1
            ? process.env.VUE_APP_ENDPOINT_API_PROD
            : process.env.VUE_APP_ENDPOINT_API_TEST
        }${API.INVITATIONS_EAN_14}?id_session=${id_session}&id_year=${
          props.payload.id_year
        }&id_event=${props.payload.id_event}&request=${localStorage.getItem(
          "tokenAdb2c"
        )}`;
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = url;
        link.setAttribute("download", `Da_confermare.csv`);
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          document.body.removeChild(link);
        }, 500);
      }
    };

    const tableHeader2 = ref([
      {
        name: "Accompagnatori",
        key: "accompagnatori",
      },
    ]);
    return {
      tableHeader2,
      scaricaSessioniCSV,
      setChecked,
      objectListFromProps,
      propose_change_overbooking_session,
      people_color,
      selected_session_name,
      propose_change_session,
      currentPage,
      tableHeader,
      tableHeaderInvitation,
      tableHeaderInvitationConsigliere,
      deleteElement,
      selected_id_session,
      selected_id_session_del,
      selected_id_session_print,
      formatDateITA,
    };
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
  min-width: 300px;
  text-align: left;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
.text-black-light {
  color: #303133 !important;
}
.min-wid-50 {
  min-width: 50px;
}
</style>
