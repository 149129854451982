
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
  getCurrentInstance,
  reactive,
} from "vue";
import arraySort from "array-sort";
import { useRouter } from "vue-router";
import "element-plus/dist/index.css";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

interface IHeaderConfiguration {
  name?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
  viewCheckboxAndName?: boolean;
}

export default defineComponent({
  name: "kt-datatable",
  emits: [
    "current-change",
    "sort",
    "items-per-page-change",
    "checkboxClicked",
    "resetFilters",
    "clickRow",
  ],
  props: {
    tableHeader: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    tableData: { type: Array, required: true },
    emptyTableText: { type: String, default: "No data found" },
    loading: { type: Boolean, default: false },
    status: { type: Number, default: 0 },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    total: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 10 },
    order: { type: String, default: "asc" },
    sortLabel: { type: String, default: "" },
    checkedAll: { type: Boolean, default: false },
    pushTo: { type: String, default: "" },
    pushToObject: { type: Object, default: null },
    nameIdToPush: { type: String, default: "id" },
    fromAffiliazione: { type: Boolean, default: false },
    customNoElementMessage: { type: String, default: "" },
    customErrorMessage: { type: String, default: "" },
    noHeader: { type: Boolean, default: false },
    collapsableLevel: { type: Number, default: 0 },
    collapsableId: { type: String },
    tabelleCompatte: { type: Boolean },
    isController: { type: Boolean },
  },
  components: {},
  setup(props, { emit }) {
    const router = useRouter();
    const data = ref(props.tableData);
    const currentSort = reactive({ key: props.sortLabel, order: props.order });
    const order = ref(props.order);
    const label = ref(props.sortLabel);
    const pagination = ref<IPagination>({
      page: props.currentPage,
      total: props.total,
      rowsPerPage: props.rowsPerPage,
    });

    watch(
      [() => props.total, () => props.currentPage, () => props.rowsPerPage],
      () => {
        pagination.value = {
          page: props.currentPage,
          total: props.total,
          rowsPerPage: props.rowsPerPage,
        };
      }
    );

    const vnodeProps = getCurrentInstance()?.vnode.props || {};

    watch(data.value, () => {
      if ("onCurrentChange" in vnodeProps) {
        currentSort.key = label.value;
        currentSort.order = order.value;
      } else {
        pagination.value.total = data.value.length;
      }
    });

    onMounted(() => {
      currentSort.key = label.value;
      currentSort.order = order.value;
      pagination.value.total = props.total ? props.total : data.value.length;
      pagination.value.rowsPerPage = props.rowsPerPage;
    });

    const getItems = computed(() => {
      if ("onCurrentChange" in vnodeProps) {
        return data.value;
      } else {
        const clone = JSON.parse(JSON.stringify(data.value));
        const startFrom =
          pagination.value.page * pagination.value.rowsPerPage -
          pagination.value.rowsPerPage;
        return clone.splice(startFrom, pagination.value.rowsPerPage);
      }
    });

    const currentPageChange = (val) => {
      if ("onCurrentChange" in vnodeProps) {
        emit("current-change", val);
      } else {
        pagination.value.page = val;
      }
    };

    const sort = (columnName, sortable) => {
      if (sortable === false) {
        return;
      }

      if ("onSort" in vnodeProps) {
        if (order.value === "asc") {
          order.value = "desc";
          emit("sort", { columnName: columnName, order: "desc" });
        } else {
          order.value = "asc";
          emit("sort", { columnName: columnName, order: "asc" });
        }
      } else {
        if (order.value === "asc") {
          order.value = "desc";
          arraySort(data.value, columnName, { reverse: false });
        } else {
          order.value = "asc";
          arraySort(data.value, columnName, { reverse: true });
        }
      }
      currentSort.key = columnName;
      currentSort.order = order.value;
      // currentSort.value = columnName + order.value;
    };

    const setItemsPerPage = (event) => {
      if ("onItemsPerPageChange" in vnodeProps) {
        emit("items-per-page-change", parseInt(event.target.value));
      } else {
        pagination.value.rowsPerPage = parseInt(event.target.value);
      }
    };
    const checkWhereToPush = (item) => {
      return item[props.nameIdToPush];
    };

    const clickRow = ({ idx, item }) => {
      emit("clickRow", { idx, item });
    };

    return {
      clickRow,
      checkWhereToPush,
      pagination,
      currentPageChange,
      getItems,
      sort,
      currentSort,
      setItemsPerPage,
      router,
      data,
    };
  },
});
