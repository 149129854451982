<template>
  <Form
    @invalid-submit="onInvalidSubmit"
    @submit="onSubmit"
    :initial-values="sessione"
    :validation-schema="schema"
    autocomplete="off"
    class="card shadow mt-3"
    :class="windowWidth <= 767 ? '' : 'me-2'"
    v-slot="{ values }"
  >
    <!-- #357ffa -->

    <div class="card-header px-3">
      <div class="my-2 d-flex w-100 justify-content-between text-white">
        <div class="fw-bold">{{ sessione.description }}</div>
        <div class="fw-bold">
          {{ moment(sessione.date).format("DD/MM/YYYY HH:mm") }}
        </div>
      </div>
    </div>

    <div class="card-body" v-if="can_set_lounge">
      <div class="row mb-2">
        <div class="col-12 d-flex p-0">
          <Field
            name="selected"
            class="align-self-start mt-2 me-2"
            type="checkbox"
            :value="true"
            :checked="values.selected"
            @change="
              $event.target.checked ? '' : (selectedAccompagnatori = []);
              $event.target.checked ? '' : (values.selected_guest = false);
              $event.target.checked ? '' : (values.lounge = false);
              onSubmit(values);
            "
            @click="nonAggiungereASessioniSelezionate = false"
            :disabled="!canEnterSession(sessione.date) || disabled"
          />
          <label class="fw-500 fs-7">Partecipo a questa sessione</label>

          <ErrorMessage v-show="view_errors" name="can_invitation_send" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-6 d-flex p-0">
          <Field
            name="selected_guest"
            id="selected_guest_id"
            class="align-self-start mt-2 me-2"
            type="checkbox"
            :value="true"
            :checked="values.selected_guest"
            :disabled="
              !values.selected ||
              (values.selected && !accompagnatoriOptions.length) ||
              disabled ||
              !canEnterSession(sessione.date)
            "
            v-model="selectedGuest"
            @click="nonAggiungereASessioniSelezionate = true"
            @change="
              $event.target.checked ? '' : (values.selected_guest = false);
              onSubmit(values);
            "
          />
          <label class="fw-500 fs-7">Ho un accompagnatore</label>

          <ErrorMessage v-show="view_errors" name="can_invitation_send" />
        </div>
        <div class="col-6 d-flex p-0">
          <Field
            name="lounge"
            class="align-self-start mt-2 me-2"
            type="checkbox"
            :value="true"
            :checked="values.lounge"
            @click="nonAggiungereASessioniSelezionate = false"
            @change="
              $event.target.checked
                ? (values.lounge = true)
                : (values.lounge = false);
              onSubmit(values);
            "
            :disabled="!values.selected || !canEnterSession(sessione.date)"
          />
          <label class="fw-500 fs-7">Ingresso alla lounge</label>

          <ErrorMessage v-show="view_errors" name="can_invitation_send" />
        </div>
      </div>

      <div class="row">
        <Multiselect
          :key="accompagnatoriOptions"
          v-model="selectedAccompagnatori"
          :options="accompagnatoriOptions"
          class="p-0"
          mode="tags"
          groupLabel="label"
          :placeholder="
            accompagnatoriOptions
              ? 'Scegli accompagnatori tra quelli in elenco'
              : 'Nessun accompagnatore disponibile'
          "
          label="nominative"
          valueProp="id_guest"
          @update:modelValue="onSubmit(values)"
          :close-on-select="false"
          :searchable="true"
          :create-option="false"
          :disabled="
            !values.selected_guest ||
            disabled ||
            !canEnterSession(sessione.date)
          "
        />
        <!-- :max="invito.max_guest_sessions" -->
        <Field name="guest" type="hidden" :value="selectedAccompagnatori" />
        <ErrorMessage
          v-if="!id_manifestazione || (id_manifestazione && loadedLookups)"
          name="guest"
        />

        <button
          type="submit"
          :id="`submit${sessione.id_session}`"
          class="d-none"
        ></button>
        <!-- <ErrorMessage v-show="view_errors" name="surname" /> -->
      </div>
    </div>
    <div class="card-body" v-else>
      <div class="row mb-2">
        <div class="col-6 d-flex p-0">
          <Field
            name="selected"
            class="align-self-start mt-2 me-2"
            type="checkbox"
            :value="true"
            :checked="values.selected"
            @change="
              $event.target.checked ? '' : (selectedAccompagnatori = []);
              $event.target.checked ? '' : (values.selected_guest = false);
              onSubmit(values);
            "
            @click="nonAggiungereASessioniSelezionate = false"
            :disabled="!canEnterSession(sessione.date) || disabled"
          />
          <label class="fw-500 fs-7">Partecipo a questa sessione</label>

          <ErrorMessage v-show="view_errors" name="can_invitation_send" />
        </div>
        <div class="col-6 d-flex p-0">
          <Field
            name="selected_guest"
            id="selected_guest_id"
            class="align-self-start mt-2 me-2"
            type="checkbox"
            :value="true"
            :checked="values.selected_guest"
            :disabled="
              !values.selected ||
              (values.selected && !accompagnatoriOptions.length) ||
              disabled ||
              !canEnterSession(sessione.date)
            "
            v-model="selectedGuest"
            @click="nonAggiungereASessioniSelezionate = true"
            @change="
              $event.target.checked ? '' : (values.selected_guest = false);
              onSubmit(values);
            "
          />
          <label class="fw-500 fs-7">Ho un accompagnatore</label>

          <ErrorMessage v-show="view_errors" name="can_invitation_send" />
        </div>
      </div>

      <div class="row">
        <Multiselect
          :key="accompagnatoriOptions"
          v-model="selectedAccompagnatori"
          :options="accompagnatoriOptions"
          class="p-0"
          mode="tags"
          groupLabel="label"
          :placeholder="
            accompagnatoriOptions
              ? 'Scegli accompagnatori tra quelli in elenco'
              : 'Nessun accompagnatore disponibile'
          "
          label="nominative"
          valueProp="id_guest"
          @update:modelValue="onSubmit(values)"
          :close-on-select="false"
          :searchable="true"
          :create-option="false"
          :disabled="
            !values.selected_guest ||
            disabled ||
            !canEnterSession(sessione.date)
          "
        />
        <!-- :max="invito.max_guest_sessions" -->
        <Field name="guest" type="hidden" :value="selectedAccompagnatori" />
        <ErrorMessage
          v-if="!id_manifestazione || (id_manifestazione && loadedLookups)"
          name="guest"
        />

        <button
          type="submit"
          :id="`submit${sessione.id_session}`"
          class="d-none"
        ></button>
        <!-- <ErrorMessage v-show="view_errors" name="surname" /> -->
      </div>
    </div>
  </Form>
</template>
<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import { useWindowWidth } from "@/composables/getWindowWidth";
import { INVITI_STATUS } from "@/enums/costanti";

export default defineComponent({
  components: {
    Multiselect,
    Form,
    Field,
    ErrorMessage,
  },
  emits: ["single_session", "guestEdit"],
  props: {
    sessione: {
      type: Object,
    },
    accompagnatori: {
      type: Array,
    },
    numberSessioniOspiteSelected: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    from_invitations: {
      type: Boolean,
    },
    from_invitations_consigliere: {
      type: Boolean,
    },
    can_set_lounge: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectedAccompagnatori = ref([]);
    const selectedGuest = ref(false);

    const nameSurname = ({ name, surname }) => {
      return `${name} ${surname}`;
    };
    const { windowWidth } = useWindowWidth();

    const nonAggiungereASessioniSelezionate = ref(false);

    const onSubmit = (values) => {
      emit("single_session", {
        values,
        selectedAccompagnatori: selectedAccompagnatori.value,
        nonAggiungereASessioniSelezionate:
          nonAggiungereASessioniSelezionate.value,
      });
      // nonAggiungereASessioniSelezionate.value = false;
    };

    watch(selectedGuest, (val) => {
      if (!val) {
        selectedAccompagnatori.value = [];
      }
    });
    const clickFromPartecipo = ref(false);

    const canEnterSession = (sessionStart) => {
      const now = new Date();
      const sessionStartDate = new Date(sessionStart);
      const twelveHoursAfterSession = new Date(
        sessionStartDate.getTime() + 12 * 60 * 60 * 1000
      );
      // sessionStartDate.getTime() - 24 * 60 * 60 * 1000
      return now < twelveHoursAfterSession;
      // return new Date(date) < new Date();
    };

    watch(
      () => props.accompagnatori,
      (newAcc) => {
        // Funzione per rimuovere elementi non corrispondenti
        selectedAccompagnatori.value = selectedAccompagnatori.value.filter(
          (id) => newAcc.some((obj) => obj.id_guest === id)
        );
      },
      { deep: true }
    );
    return {
      canEnterSession,
      nonAggiungereASessioniSelezionate,
      clickFromPartecipo,
      selectedGuest,
      onSubmit,
      moment,
      selectedAccompagnatori,
      nameSurname,
      windowWidth,
      INVITI_STATUS,
    };
  },
  computed: {
    accompagnatoriOptions() {
      let arr = [];
      if (this.accompagnatori) {
        this.accompagnatori.forEach((el) => {
          arr.push(el);
        });
      }

      return arr;
    },
  },
});
</script>
<style>
.card-header {
  background-color: #357ffa !important;
  min-height: auto !important;
  /* min-width: 350px; */
}
.multiselect-placeholder {
  font-size: small;
}
</style>
